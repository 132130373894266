
import CryptoJS from 'crypto-js'
import AES from '../AES.json'

export let AUTH_USER = 'auth_user_2024'
export let AUTH_UUID = 'auth_uuid_2024'
export let AUTH_DATE = 'auth_date_2024'

export function get_usuario() {
    if (localStorage.getItem(this.AUTH_USER) != null && localStorage.getItem(this.AUTH_UUID) != null) {
        var usuario = JSON.parse(this.aesDencrypt(localStorage.getItem(this.AUTH_USER)));
        var authUuid = this.aesDencrypt(localStorage.getItem(this.AUTH_UUID));
        if (usuario!=null && usuario!=undefined) {
            if (usuario.uuid == authUuid) {
                return usuario;
            } else {
                var contas = usuario.contasVinculadas;
                for (var i=0;i<contas.length;i++) {
                    if (contas[i]!=null && contas[i]!=undefined && contas[i].uuid == authUuid) {
                        return contas[i];
                    }
                }
                //se não achou tem algo errado... retira tudo e pede pra logar novamente.
                this.reset_usuario_logado();
                return null;
            }
        }
    }
}

export function aesEncrypt(txt) {
    if (!(typeof txt === 'string' || txt instanceof String)) {
        txt = JSON.stringify(txt)
    }
    const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(AES.AESPW), {
        iv: CryptoJS.enc.Utf8.parse(AES.AESIV),
        mode: CryptoJS.mode.CBC
    })
    return cipher.toString()
}

export function aesDencrypt(txt) {
    if (typeof txt != "string") {
        txt = JSON.stringify(txt)
    }
    const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(AES.AESPW), {
      iv: CryptoJS.enc.Utf8.parse(AES.AESIV),
      mode: CryptoJS.mode.CBC
    })

    return CryptoJS.enc.Utf8.stringify(cipher).toString()
}

export default {

    data() {
        return {
          AUTH_USER: AUTH_USER,
          AUTH_UUID: AUTH_UUID,
          AUTH_DATE: AUTH_DATE,
        };
    },
    methods: {

        get_usuario,
        aesEncrypt,
        aesDencrypt,

        get_usuario_logado() {
            try {
                if (localStorage.getItem(this.AUTH_USER) != null && localStorage.getItem(this.AUTH_UUID) != null) {
                    var usuario = JSON.parse(this.aesDencrypt(localStorage.getItem(this.AUTH_USER)));
                    var authUuid = this.aesDencrypt(localStorage.getItem(this.AUTH_UUID));
                    if (usuario!=null && usuario!=undefined) {
                        if (usuario.uuid == authUuid) {
                            return usuario;
                        } else {
                            var contas = usuario.contasVinculadas;
                            for (var i=0;i<contas.length;i++) {
                                if (contas[i]!=null && contas[i]!=undefined && contas[i].uuid == authUuid) {
                                    return contas[i];
                                }
                            }
                            //se não achou tem algo errado... retira tudo e pede pra logar novamente.
                            this.reset_usuario_logado();
                            return null;
                        }
                    } else {
                        this.reset_usuario_logado();
                        return null;
                    }
                } else {
                    this.reset_usuario_logado();
                    return null;
                }
            } catch (error) {
                this.reset_usuario_logado();
                return null;
            }
        },
        
        reset_usuario_logado() {
            localStorage.removeItem(this.AUTH_USER);
            localStorage.removeItem(this.AUTH_UUID);
            localStorage.removeItem(this.AUTH_DATE);
        },
        
        set_usuario_logado(newUser) {
            var userCriptado = this.aesEncrypt(newUser);
            localStorage.setItem(this.AUTH_USER, userCriptado);
            var user = newUser;
            if (typeof newUser === 'string' || newUser instanceof String) {
             user = JSON.parse(newUser);
            } 
            var uuidLogado = this.aesEncrypt(user.uuid);
            localStorage.setItem(this.AUTH_UUID, uuidLogado);
            localStorage.setItem(this.AUTH_DATE, new Date());
        },

        alterna_conta_logada(uuid, url_redirect) {
            // var usuario = JSON.parse(this.aesDencrypt(localStorage.getItem('auth_user')));
            var uuidLogado = this.aesEncrypt(uuid);
            localStorage.setItem(this.AUTH_UUID, uuidLogado);
            window.location.href = url_redirect;
        },

        get_contasvinculadas() {
            var usuario = null;
            try {
                usuario = JSON.parse(this.aesDencrypt(localStorage.getItem(this.AUTH_USER)));
            } catch (error) {
                usuario = null;
            }
            var retorno = [];
            if (usuario!=null && usuario!=undefined) {
                retorno.push(usuario);
                var contas = [];
                try {
                    //contas = JSON.parse(usuario.contasVinculadas);
                    if (usuario.contasVinculadas) {
                        contas = usuario.contasVinculadas;
                    } else {
                        contas = [];
                    }
                } catch (error) {
                    contas = [];
                }
                for (var i=0;i<contas.length;i++) {
                    if (contas[i]!=null && contas[i]!=undefined) {
                        retorno.push(contas[i]);
                    }
                }
                return retorno;
            } else {
                return [];
            }

        },

        isEmpty(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        },


        
    }
}