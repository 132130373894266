import Vue from 'vue'
import Router from 'vue-router'

//Real Pages
import Home from './views/Home.vue'
import Escalacao from './views/Escalacao.vue' 
import Avaliacao from './views/Avaliacao.vue' 
import TimesDoGuru from './views/TimesDoGuru.vue' 
import MeuTime from './views/MeuTime.vue' 
import Login from './views/Login.vue' 
// import LoginNovo from './views/LoginNovo.vue' 
import ParciaisValorizacao from './views/ParciaisValorizacao.vue' 
import Noticias from './views/Noticias.vue'
import Ajuda from './views/Ajuda.vue'
import Whatsapp from './views/Whatsapp.vue'
import Contas from './views/Contas.vue'
import ContasVincular from './views/ContasVincular.vue'

//Auxiliar Pages
import Faq from './views/auxiliar/Faq.vue' 
import Termos from './views/auxiliar/Termos.vue' 
import TermosApp from './views/auxiliar/TermosApp.vue' 
import Privacidade from './views/auxiliar/Privacidade.vue' 
// import Premiacao from './views/auxiliar/Premiacao.vue' 
// import PremiacaoWebview from './views/auxiliar/PremiacaoWebview.vue' 
import BoasVindas from './views/auxiliar/BoasVindas.vue' 
import Retrospectiva from './views/auxiliar/Retrospectiva.vue' 
import Mais from './views/auxiliar/Mais.vue' 
import GrupoVip from './views/auxiliar/GrupoVip.vue' 
import GrupoBet from './views/auxiliar/GrupoBet.vue' 
import GrupoLigaRodada from './views/auxiliar/GrupoLigaRodada.vue' 
import TimeGuruVip from './views/auxiliar/TimeGuruVip.vue'
import TimeGuru from './views/auxiliar/TimeGuru.vue'
import TimeGuruVip2 from './views/auxiliar/TimeGuruVip2.vue'
import Recrutamento from './views/auxiliar/Recrutamento.vue'
import RecrutamentoCoordMkt from './views/auxiliar/RecrutamentoCoordMkt.vue'
import Partida from './views/Partida.vue'
import Partidas from './views/Partidas.vue'
import MetricaDate from './views/MetricaDate.vue'
import MetricaCount from './views/MetricaCount.vue'
import MetricaPath from './views/MetricaPath.vue'
import Compra from './views/Compra.vue'
import GetnetCheckout from './views/GetnetCheckout.vue'
import GetnetPedido from './views/GetnetPedido.vue'
import CompraParciais from './views/CompraParciais.vue'
import GetnetCheckoutParciais from './views/GetnetCheckoutParciais.vue'
import GetnetPedidoParciais from './views/GetnetPedidoParciais.vue'
import NoticiasGuru from './views/NoticiasGuru.vue'
import Gurubets from './views/auxiliar/Gurubets.vue'
// import TestePremiacao from './views/tabs/TestePremiacao.vue'

//Random Pages
import Time from './views/randomPages/Time.vue' 
// import Vendas from './views/randomPages/Vendas.vue' 
import Liga1 from './views/randomPages/Liga1.vue' 

// import util from '@/mixin/util';
let util = require('@/mixin/util');

Vue.use(Router)
const router =  new Router({
mode: 'history',
routes: [

     //Redirect
          {
               path: '/timeguru',
               beforeEnter() {
                    // location.href = 'https://lp.gurudocartola.com/timeguru23'
                    // location.href = 'https://vipdoguru.com/2023'
                    location.href = 'https://gurudocartola.com/lotado/'
                    // location.href = 'https://lp.gurudocartola.com/timeguru24'
                    // var rand = Math.random();
                    // if (rand > 0.5) {
                         // location.href = 'https://gurudocartola.com/timeguruvip/'
                    // } else {
                    //      location.href =  'https://gurudocartola.com/timeguru-vip/'
                    // }
               },
               name: 'TimeGuru',
               component: TimeGuru,
               meta: {
                    webview: true
               }
          },
     //Auxiliar Pages
          {
               // alias: '/',
               path: '/retrospectiva',
               name: 'retrospectiva',
               component: Retrospectiva,
               meta: {
                    webview: true
               }
          },
          {
               path: '/gurubets',
               name: 'gurubets',
               component: Gurubets,
               meta: {
                    webview: true
               }
          },
          {
            path: '/perguntas-frequentes',
            name: 'Faq',
            component: Faq
          },
          {
            path: '/termos-de-uso',
            name: 'Termos',
            component: Termos
          },
          {
            path: '/termos-android',
            name: 'TermosApp',
            component: TermosApp
          },
          {
            path: '/politica-de-privacidade',
            name: 'Privacidade',
            component: Privacidade
          },
          // {
          //   path: '/premios-guru',
          //   name: 'Premiacao',
          //   component: Premiacao
          // },
          // {
          //      path: '/webview/premios-guru',
          //      name: 'PremiacaoWebview',
          //      component: PremiacaoWebview,
          //      meta: {
          //           webview: true
          //      }
          // },
          {
            path: '/boas-vindas',
            name: 'BoasVindas',
            component: BoasVindas,
            meta: {
               webview: true
            }
          },
          {
            path: '/mais',
            name: 'mais',
            component: Mais
          },
          {
               path: '/time-guru-mk',
               name: 'time-guru-mk',
               component: Compra,
               meta: {
                    webview: true
               }
          },
          {
               path: '/getnet/',
               name: 'getnet',
               component: GetnetCheckout,
               meta: {
                    webview: true
               }
          },
          {
               path: '/getnet/:id',
               name: 'getnet-sucesso',
               props: true,
               component: GetnetPedido,
               meta: {
                    webview: true
               }
          },
          {
               path: '/time-guru-mk-parciais',
               name: 'time-guru-mk',
               component: CompraParciais,
               meta: {
                    webview: true
               }
          },
          {
               path: '/getnet-parciais',
               name: 'getnet',
               component: GetnetCheckoutParciais,
               meta: {
                    webview: true
               }
          },
          {
               path: '/getnet-parciais/:id',
               name: 'getnet-sucesso',
               props: true,
               component: GetnetPedidoParciais,
               meta: {
                    webview: true
               }
          },

     //Random Pages
          {
            path: '/time/:userId',
            name: 'timeusuario',
            component: Time
          },
          // {
          //   path: '/vendas',
          //   name: 'vendas',
          //   component: Vendas
          // },
          {
            path: '/ligaboasvindas',
            name: 'ligaboasvindas',
            component: Liga1
          },
          {
               path: '/vip',
               name: 'vip',
               component: () => {
                    $.ajax({
                         type: "GET",
                         url: 'https://gurudocartola.com/app/var/grupovip',
                         contentType: "application/json; charset=utf-8",
                         dataType: "json",
                         success: function (result) {
                              if (result==0) {
                                   location.href = 'https://gurudocartola.com/lotado/'
                              } else {
                                   location.href = 'https://gurudocartola.com/grupovip-redirect'
                              }
                         },
                         error: function (error) {
                              // eslint-disable-next-line no-console
                              console.log('ERROR [#GV]:')
                              // eslint-disable-next-line no-console
                              console.log(error);
                         }
                    });
               }, 
               meta: {
                    webview: true
               }
          },
          {
               
               path: '/grupovip-redirect',
               // beforeEnter() {
               //      location.href = 'https://gurudocartola.com/lotado/'
               // },
               name: 'grupovip',
               component: GrupoVip,
               meta: {
                    webview: true
               }
          },
          {
               path: '/grupobet',
               name: 'grupobet',
               component: GrupoBet,
               meta: {
                    webview: true
               }
          },
          {
               path: '/vip-parciais',
               name: 'vip-parciais',
               component: GrupoLigaRodada,
               meta: {
                    webview: true
               }
          },
          {
               path: '/timeguruvip',
               name: 'timeGuruVip',
               component: TimeGuruVip,
               meta: {
                    webview: true
               }
          },
          {
               path: '/timeguru-vip',
               name: 'timeguru-vip',
               component: TimeGuruVip2,
               meta: {
                    webview: true
               }
          },
          {
               path: '/estagio',
               name: 'estagio',
               component: Recrutamento,
               meta: {
                    webview: true
               }
          },
          {
               path: '/coordenadormkt',
               name: 'coordenadormkt',
               component: RecrutamentoCoordMkt,
               meta: {
                    webview: true
               }
          },
     //Real Pages
          {
            path: '/whatsapp',
            name: 'whatsapp',
            component: Whatsapp
          },
          {
               alias: '/ajuda',
               path: '/ajuda-uso-guru-do-cartola',
               name: 'ajuda',
               component: Ajuda
             },
          // {
          //      alias: '/',
          //      path: '/',
          //      name: 'home',
          //      component: Gurubets,
          //      meta: {
          //           webview: true
          //      }
          // },
          // {
          //   path: '/',
          //   name: 'home',
          // //   component: Home
          //   component: Escalacao
          // },
          // {
          //      path: '/',
          //      name: 'home',
          //      component: Retrospectiva,
          //      meta: {
          //           webview: true,
          //           title: 'Guru do Cartola | Cartola FC',
          //           metaTags: [
          //           {
          //                name: 'description',
          //                content: 'Não entre em campo com o seu time desfalcado! Veja as últimas notícias de cada clube.'
          //           }
          //           ]
          //      }
          // },
          {
            path: '/home',
            name: 'home',
            component: Home
          },
          {
               path: '/contas',
               name: 'contas',
               component: Contas
          },
          {
               path: '/contas/vincular',
               name: 'contasVincular',
               component: ContasVincular
          },
          {
            path: '/login',
            name: 'login',
            component: Login
          },
          // {
          //      path: '/loginnovo',
          //      name: 'loginnovo',
          //      component: LoginNovo
          //    },
          {
               path: '/valorizacao-ao-vivo-cartola',
               name: 'parciaisValorizacao',
               component: ParciaisValorizacao,
               meta: {
                    title: 'Guru do Cartola | Cartola FC - Parciais e valorização ao vivo',
                    metaTags: [
                    {
                      name: 'description',
                      content: 'Acompanhe quantas cartoletas o seu time está valorizando no Cartola FC em tempo real.'
                    }
                    ]
                }
          },
          {
               alias: '/',
               path: '/escale-seu-time-cartola',
               name: 'escalacao',
               component: Escalacao
          },
          {
            path: '/estatisticas-meu-time-cartola',
            name: 'meutime',
            component: MeuTime
          },
          {
               path: '/avaliacao-time-cartola',
               name: 'avaliacao',
               component: Avaliacao,
               meta: {
                    title: 'Guru do Cartola | Cartola FC - Avaliação de times',
                    metaTags: [
                    {
                         name: 'description',
                         content: 'Avaliação do seu time para a próxima rodada do Cartola FC'
                    }
                    ]
               }
          },
          {
               path: '/selecao-guru-cartola',
               name: 'timesdoguru',
               component: TimesDoGuru,
               meta: {
                    title: 'Guru do Cartola | Seleção do Guru',
                    metaTags: [
                    {
                         name: 'description',
                         content: 'Aqui é onde os mitos moram! Seleção do Guru, Time Valorização e Times da Guruzada. Escolha a rodada para ver nossa seleção.'
                    }
                    ]
               }

          },
          {
               path: '/noticias-cartola',
               name: 'noticias',
               component: Noticias,
               meta: {
                    title: 'Guru do Cartola | Cartola FC - Notícias',
                    metaTags: [
                    {
                         name: 'description',
                         content: 'Não entre em campo com o seu time desfalcado! Veja as últimas notícias de cada clube.'
                    }
                    ]
               }
          },
          
          {
               path: '/partida/:id',
               name: 'partida',
               props: true,
               component: Partida
          },
          {
               path: '/partidas',
               name: 'partidas',
               component: Partidas
          },
          {
               path: '/metrica/date',
               name: 'metrica-date',
               component: MetricaDate
               
          },
          {
               path: '/metrica/path',
               name: 'metrica-path',
               component: MetricaPath
               
          },
          {
               path: '/metrica/count',
               name: 'metrica-count',
               component: MetricaCount
          },
          {
               path: '/gurunews',
               name: 'gurunews',
               component: NoticiasGuru
          },
          {
               path: '/gurunews-mobile',
               name: 'gurunews-mobile',
               component: NoticiasGuru,
               meta: {
                    webview: true
               }
          },
          

         
]
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
     // This goes through the matched routes from last to first, finding the closest route with a title.
     // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
     const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

     // Find the nearest route element with meta tags.
     const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
     // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

     // If a route with a title was found, set the document (page) title to that value.
     if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

     // Remove any stale meta tags from the document using the key attribute we set below.
     Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

     // Skip rendering meta tags if there are none.
     if(!nearestWithMeta) return next();

     // Turn the meta tag definitions into actual elements in the head.
     nearestWithMeta.meta.metaTags.map(tagDef => {
     const tag = document.createElement('meta');

     Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
     });

     // We use this to track which meta tags we create, so we don't interfere with other ones.
     tag.setAttribute('data-vue-router-controlled', '');

     return tag;
     })
     // Add the meta tags to the document head.
     .forEach(tag => document.head.appendChild(tag));


     //Salva o ultimo acesso do usuario
     const usr = util.get_usuario();
     const lastUpdate = new Date(localStorage.getItem('last_update'));
     const diff = Math.abs(new Date() - lastUpdate) ;
     const oneDayInMils = 24 * 60 * 60 *  1000;
     if (usr != null && usr != undefined && (lastUpdate == null || diff>oneDayInMils)) {
          localStorage.setItem('last_update', new Date());
          const call_url = 'https://gurudocartola.com/app/user/update_access/'+usr.uuid;
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({"plataforma":1});
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(call_url, requestOptions);
          //   .then(response => console.log(response.text()));
          //   .then(result => console.log(result))
          //   .catch(error => console.log('error', error));
     }
     
     next();
});

export default router;
