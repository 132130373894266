<template>
     <div>
          <customHeader title="Escale o seu time" subtitle="Escale o seu time no Cartola FC com as estatísticas, mapas de calor, comparações, scouts e análises do adversário aqui no Guru do Cartola." :mobile="mobile" :showBanner="showBanner"></customHeader>
          <div class="d-flex flex-column">
               <div class="bg-grass-light bg-verde header-laranja div-clube-name" v-if="warning != 'login' && warning != 'time' && warning != 'busca' && user && user.nomeTime">
                    <!-- <div class="col22-12"> -->
                         <h3 class="clube-name text-center" >{{ user.nomeTime }}</h3>
                         <!-- style="padding-bottom:1rem; border-bottom:2px solid orange;"-->
                    <!-- </div> -->
               </div>
               <div id="escalacao" class="d-flex flex-row" >
                    <template v-if="warning != 'login'">
                         <div class="flex-column" :class="mobile?'col-12 offset-md-1':'col-7'">
                              <div style="padding:0px" id="fieldToShare">
                                   <div v-if="fieldView == 'both' || fieldView == 'field' " id="field">
                                        <div class="row">
                                             <div>
                                                  <select id="selectTactic" class="form-control"
                                                       @change="change_tatic()"
                                                       v-model="desired_tactic"
                                                       :style="[messages.length != 0 ? {'background-color':'#dc3545'} : {'background-color':'#28a745'}]">
                                                       <option v-for="tactic in tactics" 
                                                            :value="tactic.esquema_id" 
                                                            :key="tactic.esquema_id">
                                                            {{tactic.nome}}
                                                       </option>
                                                  </select>
                                             </div>
                                        </div>
                                        
                                        <div id="team">
                                             <div id="attack" class="row">
                                                  <template v-for="(athlete,n) in userTeam.atletas" >
                                                       <div v-if="athlete.ps == 5" class="col" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = athlete.id" :key="'ATA'+n">
                                                            <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                 :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange'} : {}]"
                                                                 @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                 class="fab fa-cuttlefish"></i>
                                                            <i v-if="athlete_hover == athlete.id" 
                                                                 @click="sell_athlete(athlete)" 
                                                                 class="fas fa-times"></i>
                                                            <img :src="athlete_photo(athlete.ft)" class="img img-fluid" 
                                                                 :class="[athlete_hover == athlete.id ? 'athlete_img_hover' : '', getClassJogador(athlete,false)]"
                                                            >
                                                                 <!-- :style="[athlete.st != 7 ? {'border':'2px solid red'} : {}]" -->
                                                            <img v-if="app_escalar_jogadores[athlete.id] && clubs[app_escalar_jogadores[athlete.id].cb]" 
                                                                 :src="clubs[app_escalar_jogadores[athlete.id].cb].ec" class="club2">
                                                            <br>
                                                            <div class="pname">
                                                                 <span>{{first_name(athlete.ap)}} </span>
                                                            </div>
                                                       </div>
                                                  </template>
                                                  <template v-if="remaining_positions.ata > 0 && userTeam.atletas.length < 12 && messages.length == 0">
                                                       <div v-for="(i,n) in remaining_positions.ata"  class="col" :key="'REMATA'+n">
                                                            <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['5'],false)" class="img img-fluid plus_icon"><br>
                                                            <span style="visibility:hidden">&nbsp;</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="medium" class="d-flex row" style="">
                                                  <template v-for="(athlete,n) in userTeam.atletas">
                                                       <div v-if="athlete.ps == 4" class="col"  @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = athlete.id" :key="'MED'+n">
                                                            <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                 :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange'} : {}]"
                                                                 @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                 class="fab fa-cuttlefish"></i>
                                                            <i v-if="athlete_hover == athlete.id" 
                                                                 @click="sell_athlete(athlete)" 
                                                                 class="fas fa-times"></i>
                                                            <img :src="athlete_photo(athlete.ft)" class="img img-fluid" 
                                                                 :class="[athlete_hover == athlete.id ? 'athlete_img_hover' : '', getClassJogador(athlete,false)]"
                                                                 >
                                                                 <!-- :style="[athlete.st != 7 ? {'border':'2px solid red'} : {}]" -->
                                                            <img v-if="app_escalar_jogadores[athlete.id] && clubs[app_escalar_jogadores[athlete.id].cb]" 
                                                                 :src="clubs[app_escalar_jogadores[athlete.id].cb].ec" class="club2">
                                                            <br>
                                                            <div class="pname">
                                                                 <span class="">{{first_name(athlete.ap)}}</span>
                                                            </div>
                                                       </div>
                                                  </template>
                                                  <template v-if="remaining_positions.mei > 0 && userTeam.atletas.length < 12 && messages.length == 0">
                                                       <div v-for="(i,n) in remaining_positions.mei" class="col" :key="'REMMED'+n">
                                                            <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['4'],false)" class="img img-fluid plus_icon"><br>
                                                            <span style="visibility:hidden">&nbsp;</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="back" class="row">
                                                  <template v-for="(athlete) in userTeam.atletas" >
                                                       <div v-if="athlete.ps == 2"
                                                            class="col" 
                                                            :class="class_laterais(athlete.id)"
                                                            @mouseleave="athlete_hover = ''" 
                                                            @mouseover="athlete_hover = athlete.id"
                                                            :key="'2_'+athlete.id">
                                                            <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                 :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange'} : {}]"
                                                                 @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                 class="fab fa-cuttlefish"></i>
                                                            <i v-if="athlete_hover == athlete.id" 
                                                                 @click="sell_athlete(athlete)" 
                                                                 class="fas fa-times"></i>
                                                            <img :src="athlete_photo(athlete.ft)" class="img img-fluid" 
                                                                 :class="[athlete_hover == athlete.id ? 'athlete_img_hover' : '', getClassJogador(athlete,false)]"
                                                                 >
                                                                 <!-- :style="[athlete.st != 7 ? {'border':'2px solid red'} : {}]" -->
                                                            <img v-if="app_escalar_jogadores[athlete.id] && clubs[app_escalar_jogadores[athlete.id].cb]" 
                                                                 :src="clubs[app_escalar_jogadores[athlete.id].cb].ec" class="club2">
                                                            <br>
                                                            <div class="pname">
                                                                 <span>{{first_name(athlete.ap)}} </span>
                                                            </div>
                                                       </div>
                                                       <div v-if="athlete.ps == 3" class="col order-2" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = athlete.id" :key="'3_'+athlete.id">
                                                            <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                 :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange'} : {}]"
                                                                 @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                 class="fab fa-cuttlefish"></i>
                                                            <i v-if="athlete_hover == athlete.id" 
                                                                 @click="sell_athlete(athlete)" 
                                                                 class="fas fa-times"></i>
                                                            <img :src="athlete_photo(athlete.ft)" class="img img-fluid" 
                                                                 :class="[athlete_hover == athlete.id ? 'athlete_img_hover' : '', getClassJogador(athlete,false)]"
                                                                 >
                                                                 <!-- :style="[athlete.st != 7 ? {'border':'2px solid red'} : {}]" -->
                                                            <img v-if="app_escalar_jogadores[athlete.id] && clubs[app_escalar_jogadores[athlete.id].cb]" 
                                                                 :src="clubs[app_escalar_jogadores[athlete.id].cb].ec" class="club2">
                                                            <br>
                                                            <div class="pname">
                                                                 <span>{{first_name(athlete.ap)}} </span>
                                                            </div>
                                                       </div>
                                                  </template>
                                                  <template v-if="remaining_positions.lat + remaining_positions.zag > 0 && userTeam.atletas.length < 12 && messages.length == 0">
                                                       <div v-for="(i,n) in remaining_positions.lat" 
                                                            :class="[i == remaining_positions.lat - 1 ? 'col order-last' : 'col order-first']" :key="'BAC'+n">
                                                            <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['2'],false)" class="img img-fluid plus_icon"><br>
                                                            <span style="visibility:hidden">&nbsp;</span>
                                                       </div>
                                                       <div v-for="(i,n) in remaining_positions.zag" class="col order-2" :key="'REMBAC'+n">
                                                            <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['3'],false)" class="img img-fluid plus_icon"><br>
                                                            <span style="visibility:hidden">&nbsp;</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="goalkeeper" class="row">
                                                  <template v-for="(athlete,n) in userTeam.atletas">
                                                       <div v-if="athlete.ps == 1" class="col" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = athlete.id" :key="'GOL'+n">
                                                            <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                 :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange'} : {}]"
                                                                 @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                 class="fab fa-cuttlefish"></i>
                                                            <i v-if="athlete_hover == athlete.id" 
                                                                 @click="sell_athlete(athlete)" 
                                                                 class="fas fa-times"></i>
                                                            <img :src="athlete_photo(athlete.ft)" class="img img-fluid" 
                                                                 :class="[athlete_hover == athlete.id ? 'athlete_img_hover' : '', getClassJogador(athlete,false)]"
                                                                 >
                                                                 <!-- :style="[athlete.st != 7 ? {'border':'2px solid red'} : {}]" -->
                                                            <img v-if="app_escalar_jogadores[athlete.id] && clubs[app_escalar_jogadores[athlete.id].cb]" 
                                                                 :src="clubs[app_escalar_jogadores[athlete.id].cb].ec" class="club2">
                                                            <br>
                                                            <div class="pname">
                                                                 <span>{{first_name(athlete.ap)}}</span>
                                                            </div>
                                                       </div>
                                                  </template>
                                                  <template v-if="remaining_positions.gol > 0 && userTeam.atletas.length < 12 && messages.length == 0">
                                                       <div v-for="(i,n) in remaining_positions.gol" class="col" :key="'REMGOL'+n">
                                                            <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['1'],false)" class="img img-fluid plus_icon"><br>
                                                            <span style="visibility:hidden">&nbsp;</span>
                                                       </div>
                                                  </template>
                                                  <div id="coach">
                                                       <template v-for="(athlete,n) in userTeam.atletas">
                                                            <div v-if="athlete.ps == 6" class="colt" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = athlete.id" :key="'COA'+n">
                                                                 <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                      :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange'} : {}]"
                                                                      @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                      class="fab fa-cuttlefish"></i>
                                                                 <i v-if="athlete_hover == athlete.id" 
                                                                      @click="sell_athlete(athlete)" 
                                                                      class="fas fa-times"></i>
                                                                 <img :src="athlete_photo(athlete.ft)" class="img img-fluid" 
                                                                      :class="[athlete_hover == athlete.id ? 'athlete_img_hover' : '', getClassJogador(athlete,false)]"
                                                                      >
                                                                      <!-- :style="[athlete.st != 7 ? {'border':'2px solid red'} : {}]" -->
                                                                 <img v-if="app_escalar_jogadores[athlete.id] && clubs[app_escalar_jogadores[athlete.id].cb]" 
                                                                      :src="clubs[app_escalar_jogadores[athlete.id].cb].ec" class="club2">
                                                                 <br>
                                                                 <div class="pname">
                                                                      <span>{{first_name(athlete.ap)}} </span>
                                                                 </div>
                                                            </div>
                                                       </template>
                                                       <template v-if="remaining_positions.tec > 0 && userTeam.atletas.length < 12 && messages.length == 0">
                                                            <div v-for="(i,n) in remaining_positions.tec" class="col" :key="'REMCOA'+n" >
                                                                 <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['6'],false)" class="img img-fluid plus_icon"><br>
                                                                 <span style="visibility:hidden">&nbsp;</span>
                                                            </div>
                                                       </template>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div id="reservas" style="margin-left:1em; margin-right:1em;">
                                   <div class="row">
                                        <div class="col-12" style="padding: 0px 0px;">
                                             <h5 class="titulo-reservas">BANCO DE RESERVAS</h5> 
                                        </div>
                                   </div>
                                   <div class="row" style="max-height: 105px;">
                                        <template v-if="reserva1!=null">
                                             <div class="col order-1 text-center res" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = reserva1.id">
                                                  <i v-if="athlete_hover == reserva1.id" 
                                                       @click="remove_reserva(reserva1)" 
                                                       class="fas fa-times"></i>
                                                  <!-- {{ reserva1.id }} -->
                                                  <!-- <i v-if="reserva_luxo_id == reserva1.id" class="fas fa-refresh"></i> -->
                                                  <img :src="athlete_photo(reserva1.ft)" :class="[athlete_hover == reserva1.id ? 'athlete_img_hover' : '', getClassJogador(reserva1,true)]"  class="img img-fluid">
                                                  <div v-if="athlete_hover == reserva1.id || reserva_luxo_id == reserva1.id" class="luxo" 
                                                       @click="altera_reserva_luxo(reserva1.id)">
                                                       <img src="/img/reserva_luxo.svg" style="width:100%"/>
                                                  </div>
                                                       <!-- :class="[athlete_hover == reserva1.id ? 'athlete_img_hover' : '']"
                                                       :style="[reservaHasError(1) ? {'border':'2px solid red'} : {}]" -->
                                                  <img v-if="app_escalar_jogadores[reserva1.id] && clubs[app_escalar_jogadores[reserva1.id].cb]" 
                                                       :src="clubs[app_escalar_jogadores[reserva1.id].cb].ec" class="club2">
                                                  <br>
                                                  <span class="span-reserva">{{first_name(reserva1.ap)}}</span>
                                                  <br/>
                                                  <p class="respos">GOL</p>
                                             </div>
                                        </template>
                                        <template v-else>
                                             <div class="col order-1 text-center">
                                                  <!-- <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['1'],true)" class="img img-fluid plus_icon orange"><br> -->
                                                  <i @click="go_to_table(['1'],true)" class="fas fa-plus-circle add-btn" ></i>
                                                  <!-- <span style="visibility:hidden">&nbsp;</span> -->
                                                  <p class="respos">GOL</p>
                                             </div>
                                        </template>
                                        <!-- {{desired_tactic}} -->
                                        <template v-if="esquemaPossuiLateral()">
                                             <template v-if="reserva2!=null">
                                                  <div class="col order-2 text-center res" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = reserva2.id">
                                                       <i v-if="athlete_hover == reserva2.id" 
                                                            @click="remove_reserva(reserva2)" 
                                                            class="fas fa-times"></i>
                                                       <!-- {{ reserva2.id }} -->
                                                       <!-- <i v-if="reserva_luxo_id == reserva2.id" class="fas fa-refresh"></i> -->
                                                       <img :src="athlete_photo(reserva2.ft)" :class="[athlete_hover == reserva2.id ? 'athlete_img_hover' : '', getClassJogador(reserva2,true)]"  class="img img-fluid">
                                                       <div v-if="athlete_hover == reserva2.id || reserva_luxo_id == reserva2.id" class="luxo" 
                                                            @click="altera_reserva_luxo(reserva2.id)">
                                                            <img src="/img/reserva_luxo.svg" style="width:100%"/>
                                                       </div>
                                                       <!-- <img :src="athlete_photo(reserva2.ft)" class="img img-fluid" 
                                                            :class="[athlete_hover == reserva2.id ? 'athlete_img_hover' : '']"
                                                            :style="[reservaHasError(2) ? {'border':'2px solid red'} : {}]"
                                                            > -->
                                                       <img v-if="app_escalar_jogadores[reserva2.id] && clubs[app_escalar_jogadores[reserva2.id].cb]" 
                                                            :src="clubs[app_escalar_jogadores[reserva2.id].cb].ec" class="club2">
                                                       <br>
                                                       <span class="span-reserva">{{first_name(reserva2.ap)}}</span>
                                                       <br/>
                                                       <p class="respos">LAT</p>
                                                  </div>
                                             </template>
                                             <template v-else>
                                                  <div class="col order-2 text-center">
                                                       <!-- <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['2'],true)" class="img img-fluid plus_icon"><br> -->
                                                       <i @click="go_to_table(['2'],true)" class="fas fa-plus-circle add-btn" ></i>
                                                       <!-- <span style="visibility:hidden">&nbsp;</span> -->
                                                       <p class="respos">LAT</p>
                                                  </div>
                                             </template>
                                        </template>
                                        <template v-if="reserva3!=null">
                                             <div class="col order-3  text-center res" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = reserva3.id">
                                                  <i v-if="athlete_hover == reserva3.id" 
                                                       @click="remove_reserva(reserva3)" 
                                                       class="fas fa-times"></i>
                                                       <!-- {{ reserva3.id }} -->
                                                  <!-- <i v-if="reserva_luxo_id == reserva3.id" class="fas fa-refresh"></i> -->
                                                  <img :src="athlete_photo(reserva3.ft)" :class="[athlete_hover == reserva3.id ? 'athlete_img_hover' : '', getClassJogador(reserva3,true)]"  class="img img-fluid">
                                                  <div v-if="athlete_hover == reserva3.id || reserva_luxo_id == reserva3.id" class="luxo" 
                                                       @click="altera_reserva_luxo(reserva3.id)">
                                                       <img src="/img/reserva_luxo.svg" style="width:100%"/>
                                                  </div>
                                                  <!-- <img :src="athlete_photo(reserva3.ft)" class="img img-fluid" 
                                                       :class="[athlete_hover == reserva3.id ? 'athlete_img_hover' : '']"
                                                       :style="[reservaHasError(3) ? {'border':'2px solid red'} : {}]"
                                                       > -->
                                                  <img v-if="app_escalar_jogadores[reserva3.id] && clubs[app_escalar_jogadores[reserva3.id].cb]" 
                                                       :src="clubs[app_escalar_jogadores[reserva3.id].cb].ec" class="club2">
                                                  <br>
                                                  <span class="span-reserva">{{first_name(reserva3.ap)}}</span>
                                                  <br/>
                                                  <p class="respos">ZAG</p>
                                             </div>
                                        </template>
                                        <template v-else>
                                             <div class="col order-3  text-center">
                                                  <!-- <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['3'],true)" class="img img-fluid plus_icon"><br> -->
                                                  <i @click="go_to_table(['3'],true)" class="fas fa-plus-circle add-btn" ></i>
                                                  <!-- <span style="visibility:hidden">&nbsp;</span> -->
                                                  <p class="respos">ZAG</p>
                                             </div>
                                        </template>
                                        <template v-if="reserva4!=null">
                                             <div class="col order-4  text-center res" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = reserva4.id">
                                                  <i v-if="athlete_hover == reserva4.id" 
                                                       @click="remove_reserva(reserva4)" 
                                                       class="fas fa-times"></i>
                                                  <!-- {{ reserva4.id }} -->
                                                  <!-- <i v-if="reserva_luxo_id == reserva4.id" class="fas fa-refresh"></i> -->
                                                  <img :src="athlete_photo(reserva4.ft)" :class="[athlete_hover == reserva4.id ? 'athlete_img_hover' : '', getClassJogador(reserva4,true)]"  class="img img-fluid">
                                                  <div v-if="athlete_hover == reserva4.id || reserva_luxo_id == reserva4.id" class="luxo" 
                                                       @click="altera_reserva_luxo(reserva4.id)">
                                                       <img src="/img/reserva_luxo.svg" style="width:100%"/>
                                                  </div>
                                                  <!-- <img :src="athlete_photo(reserva4.ft)" class="img img-fluid" 
                                                       :class="[athlete_hover == reserva4.id ? 'athlete_img_hover' : '']"
                                                       :style="[reservaHasError(4) ? {'border':'2px solid red'} : {}]"
                                                       > -->
                                                  <img v-if="app_escalar_jogadores[reserva4.id] && clubs[app_escalar_jogadores[reserva4.id].cb]" 
                                                       :src="clubs[app_escalar_jogadores[reserva4.id].cb].ec" class="club2">
                                                  <br>
                                                  <span class="span-reserva">{{first_name(reserva4.ap)}}</span>
                                                  <br/>
                                                  <p class="respos">MEI</p>
                                             </div>
                                        </template>
                                        <template v-else>
                                             <div class="col order-4 text-center">
                                                  <!-- <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['4'],true)" class="img img-fluid plus_icon"><br> -->
                                                  <i @click="go_to_table(['4'],true)" class="fas fa-plus-circle add-btn" ></i>
                                                  <!-- <span style="visibility:hidden">&nbsp;</span> -->
                                                  <p class="respos">MEI</p>
                                             </div>
                                        </template>
                                        <template v-if="reserva5!=null">
                                             <div class="col order-5  text-center res" @mouseleave="athlete_hover = ''" @mouseover="athlete_hover = reserva5.id">
                                                  <i v-if="athlete_hover == reserva5.id" 
                                                       @click="remove_reserva(reserva5)" 
                                                       class="fas fa-times"></i>
                                                  <!-- {{ reserva5.id }} -->
                                                  <!-- <i class="fas fa-times"></i> -->
                                                  <img :src="athlete_photo(reserva5.ft)" :class="[athlete_hover == reserva5.id ? 'athlete_img_hover' : '', getClassJogador(reserva5,true)]"  class="img img-fluid">
                                                  <!-- <i v-if="reserva_luxo_id == reserva5.id" class="fas fa-refresh" style="position:absolute;width:30px;margin-left:10px; background-color:#FFA500;right:25%;top:-10px;"></i> -->
                                                  <div v-if="athlete_hover == reserva5.id || reserva_luxo_id == reserva5.id" class="luxo" 
                                                       @click="altera_reserva_luxo(reserva5.id)">
                                                       <img src="/img/reserva_luxo.svg" style="width:100%"/>
                                                  </div>
                                                  
                                                  <!-- <img :src="athlete_photo(reserva5.ft)" class="img img-fluid" 
                                                       :class="[athlete_hover == reserva5.id ? 'athlete_img_hover' : '']"
                                                       :style="[reservaHasError(5) ? {'border':'2px solid red'} : {}]"
                                                       > -->
                                                  <img v-if="app_escalar_jogadores[reserva5.id] && clubs[app_escalar_jogadores[reserva5.id].cb]" 
                                                       :src="clubs[app_escalar_jogadores[reserva5.id].cb].ec" class="club2">
                                                  <br>
                                                  <span class="span-reserva">{{first_name(reserva5.ap)}}</span>
                                                  <br/>
                                                  <p class="respos">ATA</p>
                                             </div>
                                        </template>
                                        <template v-else>
                                             <div class="col order-5 text-center">
                                                  <!-- <img src="https://cdn1.iconfinder.com/data/icons/ui-colored-1/100/UI__2-512.png" @click="go_to_table(['5'],true)" class="img img-fluid plus_icon"><br> -->
                                                  <i @click="go_to_table(['5'],true)" class="fas fa-plus-circle add-btn" ></i>
                                                  <!-- <span style="visibility:hidden">&nbsp;</span> -->
                                                  <p class="respos">ATA</p>
                                             </div>
                                        </template>
                                   </div>
                              </div>
                         </div>
                         <div class="col-5" v-if="!mobile">
                              <template v-if="true">
                              <!-- <template v-if="market_status && market_status.status_mercado == 1 && warning != 'login' && warning != 'time' && warning != 'busca'"> -->
                                   <div v-if="mobile && fieldView == 'field'" id="details" class="col-12 text-center">
                                        <p>
                                             <b>Preço do time:</b> {{get_userTeam_total_price().toFixed(2)}}&nbsp;
                                             <b>Você ainda tem:</b> {{get_userTeam_remaining_money()}}
                                             <br><br><br>
                                        </p>
                                   </div>
                                   
                                   <div class="" style="padding:0px">
                                        <div v-if="fieldView == 'both' || fieldView == 'table'" id="field_table">
                                             <div v-if="mobile" class="row">
                                                  <div :class="[mobile ? 'col-4' : 'col-2']">
                                                  </div>
                                                  <div v-if="mobile" :class="[mobile ? 'col-8' : 'col-10']">
                                                       <div style="float:right;">
                                                            <button class="btn btn-sm btn-light" @click="fieldView='field'">
                                                                 <i class="far fa-futbol"></i>
                                                            </button>&nbsp;
                                                            <button class="btn btn-sm btn-light" @click="fieldView='table'">
                                                                 <i class="fas fa-table"></i>
                                                            </button>
                                                       </div>
                                                  </div>
                                                  <hr>
                                             </div>
                                             <div class="row">
                                                  <div class="col-6">
                                                       <span>
                                                            <p style="font-size: 12px;">
                                                                 <b>Preço time: </b> <span class="guru-value">{{get_userTeam_total_price().toFixed(2)}}</span>
                                                            </p>
                                                       </span>
                                                  </div>
                                                  <div class="col-6">
                                                       <p style="font-size: 12px;">
                                                            <b>Você ainda tem: </b> <span class="guru-value">{{get_userTeam_remaining_money()}}</span><br>
                                                       </p>
                                                  </div>
                                             </div>
                                             <table class="table-hover" style="margin-top:-10px; margin-bottom:10px;">
                                                  <thead class="theader">
                                                       <th style="border-top-left-radius:5px; text-align:left;" >
                                                       &nbsp;
                                                       </th>
                                                       <th>Média</th>
                                                       <th>Preço</th>
                                                       <th v-if="user!= null && user.membro == 1" style="white-space:nowrap;padding-left:0px;" colspan="2">
                                                            <select class="form form-control tableVariable field-selector" v-model="tableVariable" style="padding-left:0px;">
                                                                 <option value="pm">Pont. Min.</option>
                                                                 <option value="pe">Pont. Esp.</option>
                                                                 <option value="ve">Val. Esp.</option>
                                                            </select>
                                                       </th>
                                                       <th v-else style="white-space:nowrap;padding-left:0px;" colspan="2">
                                                            Pont. Min.
                                                       </th>
                                                       <th></th>
                                                  </thead>
                                                  <tbody v-if="userTeam && tactics[userTeam.esquema_id]">
                                                       <template v-for="(tactic,index) in tactics[userTeam.esquema_id].posicoes">
                                                            <tr v-for="(athlete,n) in get_athletes_at(index)"
                                                                 :style="[athlete != null && athlete_hover == athlete.id ? {'background-color':'rgba(0,0,0,.075)'} : {}]"
                                                                 @mouseleave="athlete_hover = ''" 
                                                                 @mouseover="athlete_hover = athlete!=null?athlete.id:''" 
                                                                 :key="'POS'+index+'_'+n">
                                                                 
                                                                 <td v-if="athlete == null && remaining_positions[index] > 0 && userTeam.atletas.length < 12 && messages.length == 0" style="text-transform: uppercase;">
                                                                      <small style="color:gray; text-transform:uppercase;">
                                                                           ({{index}})
                                                                      </small>
                                                                 </td>

                                                                 <td v-if="athlete != null" style="width: 40%; white-space:nowrap">
                                                                      <small style="color:gray; text-transform:uppercase;">
                                                                           ({{positions[athlete.ps].abreviacao}}) 
                                                                      </small>
                                                                      <i v-if="userTeam.capitao_id == athlete.id || athlete_hover == athlete.id" 
                                                                           :style="[userTeam.capitao_id == athlete.id ? {'background-color':'orange', 'color':'white'} : {}]"
                                                                           @click="[userTeam.capitao_id==athlete.id ? userTeam.capitao_id = '' : userTeam.capitao_id = athlete.id]" 
                                                                           class="fab fa-cuttlefish"></i>
                                                                      <span style="white-space: nowrap;">
                                                                           {{athlete.ap}}&nbsp;
                                                                           <span v-if="athlete.st == 7">
                                                                                
                                                                           </span>
                                                                           <span v-else-if="athlete.st == 5">
                                                                                <i class="fas fa-cross" style="color:#dc3545;"></i>
                                                                           </span>
                                                                           <span v-else-if="athlete.st == 2">
                                                                                <i class="fas fa-question"></i>
                                                                           </span>
                                                                           <span v-else-if="athlete.st == 3">
                                                                                <img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso">
                                                                           </span>
                                                                           <span v-else-if="athlete.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar">
                                                                                <i class="fas fa-exclamation" style="color:#dc3545;"></i>
                                                                           </span>
                                                                      </span>
                                                                 </td>

                                                                 <td v-if="athlete != null && app_escalar_jogadores[athlete.id]" class="text-center">
                                                                      <span class="small" v-if="app_escalar_jogadores[athlete.id]['ms'+currentHome]=='ND'">indisponível</span>
                                                                      <template v-else> 
                                                                      {{app_escalar_jogadores[athlete.id]['ms'+currentHome]}}
                                                                      </template>
                                                                 </td>
                                                                 <td v-else>
                                                                      <span class="small">indisponível</span>
                                                                 </td>


                                                                 <td v-if="athlete != null" class="text-center">
                                                                      {{athlete.pr}}
                                                                 </td>

                                                                 <td v-if="athlete != null && app_escalar_jogadores[athlete.id] && app_escalar_jogadores[athlete.id][tableVariable]" class="text-center">
                                                                      <template v-if="tableVariable=='pe' && userTeam.capitao_id == athlete.id">
                                                                           {{parseFloat(app_escalar_jogadores[athlete.id][tableVariable] * 1.5).toFixed(2) }}<i class="info-cap fa-solid fa-circle-info" data-toggle="tooltip" :title="app_escalar_jogadores[athlete.id][tableVariable]+' * 1.5 = '+ app_escalar_jogadores[athlete.id][tableVariable]*1.5"></i>
                                                                           <!-- <span ">*</span> -->
                                                                      </template>
                                                                      <template v-else>
                                                                           {{parseFloat(app_escalar_jogadores[athlete.id][tableVariable]).toFixed(2)}}
                                                                      </template>
                                                                 </td>
                                                                 <td v-else>
                                                                      &nbsp;
                                                                 </td>

                                                                 <td v-if="athlete != null">
                                                                      <button @click="sell_athlete(athlete)" class="btn btn-sm btn-outline-danger btn-vender-adicionar" style="float:right; width: 60px;">
                                                                           Vender
                                                                      </button>
                                                                 </td>
                                                                 <td v-else-if="athlete == null && remaining_positions[index] > 0 && userTeam.atletas.length < 12 && messages.length == 0" colspan = 4>
                                                                      <button 
                                                                      @click="go_to_table(index,false)"
                                                                      class="btn btn-sm btn-outline-success btn-vender-adicionar r_add"
                                                                      style="float:right; width: 60px;">
                                                                           Adicionar
                                                                      </button>
                                                                 </td>
                                                            </tr>
                                                       </template>
                                                  </tbody>
                                                  <tfoot class="tfooter">
                                                       <tr>
                                                            <td class="text-center">Totais:</td>
                                                            <td class="text-center">{{somaMedia.toFixed(2)}}</td>
                                                            <td class="text-center">{{somaPreco.toFixed(2)}}</td>
                                                            <td class="text-center">{{somaPontMin.toFixed(2)}}</td>
                                                            <td class="text-right" style="padding-right: 5px;">
                                                                 <button @click="userTeam.atletas = []" class="btn btn-sm btn-outline-danger btn-trash" style="width: 60px;">
                                                                      <i class="far fa-trash-alt"></i> Todos
                                                                 </button>
                                                            </td>
                                                       </tr>
                                                  </tfoot>
                                             </table>
                                             <!-- ------------------------------------------------------------------------------------- -->
                                             <!-- ---------------------------------RESERVAS-------------------------------------------- -->
                                             <!-- ------------------------------------------------------------------------------------- -->
                                             <h5 class="titulo-reservas2">RESERVAS</h5>
                                             <table id="tbl-reservas" class="table-hover" style="border-bottom:2px solid lightgray; padding-bottom: 10px; margin-bottom: 5px;">
                                                  <tbody v-if="userTeam">
                                                       <tr v-if="reserva1!=null" :style="[athlete_hover == reserva1.id ? {'background-color':'rgba(0,0,0,.075)'} : {}]"
                                                            @mouseleave="athlete_hover = ''"  @mouseover="athlete_hover = reserva1.id">

                                                            <td style="width: 40%; white-space:nowrap">
                                                                 <small style="color:gray; text-transform:uppercase;">({{positions[reserva1.ps].abreviacao}}) </small>
                                                                 <span v-if="reserva1.id == reserva_luxo_id"><img src="img/reserva_luxo.svg" style="width:20px" title="Reserva de Luxo"></span>
                                                                 <span style="white-space: nowrap;">
                                                                      {{reserva1.ap}}&nbsp; 
                                                                      <span v-if="reserva1.st == 7"></span>
                                                                      <span v-else-if="reserva1.st == 5"><i class="fas fa-cross" style="color:#dc3545;"></i></span>
                                                                      <span v-else-if="reserva1.st == 2"><i class="fas fa-question"></i></span>
                                                                      <span v-else-if="reserva1.st == 3"><img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso"></span>
                                                                      <span v-else-if="reserva1.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar"><i class="fas fa-exclamation" style="color:#dc3545;"></i></span>
                                                                 </span>
                                                            </td>

                                                            <td v-if="app_escalar_jogadores[reserva1.id]" class="text-center">
                                                                 <span v-if="app_escalar_jogadores[reserva1.id]['ms'+currentHome]=='ND'" class="small" >indisponível</span>
                                                                 <template v-else>{{app_escalar_jogadores[reserva1.id]['ms'+currentHome]}}</template>
                                                            </td>
                                                            <td v-else><span class="small">indisponível</span></td>

                                                            <td class="text-center">{{reserva1.pr}}</td>

                                                            <td v-if="app_escalar_jogadores[reserva1.id] && app_escalar_jogadores[reserva1.id][tableVariable]" class="text-center">{{app_escalar_jogadores[reserva1.id][tableVariable]}}</td>
                                                            <td v-else>&nbsp;</td>

                                                            <td>
                                                                 <button @click="remove_reserva(reserva1)" class="btn btn-sm btn-remover" style="float:right; width: 60px;">Remover</button>
                                                            </td>
                                                       </tr>
                                                       <tr v-else>
                                                            <td colspan="4">Goleiro Reserva</td>
                                                            <td>
                                                                 <button  @click="go_to_table(['1'],true)" class="btn btn-sm btn-outline-success btn-reserva r_add" style="float:right; width: 60px;">Reserva</button>
                                                            </td>
                                                       </tr>
                                                       
                                                       <template v-if="esquemaPossuiLateral()">
                                                            <tr v-if="reserva2!=null" :style="[athlete_hover == reserva2.id ? {'background-color':'rgba(0,0,0,.075)'} : {}]"
                                                                 @mouseleave="athlete_hover = ''"  @mouseover="athlete_hover = reserva2.id">

                                                                 <td style="width: 40%; white-space:nowrap">
                                                                      <small style="color:gray; text-transform:uppercase;">({{positions[reserva2.ps].abreviacao}}) </small>
                                                                      <span v-if="reserva2.id == reserva_luxo_id"><img src="img/reserva_luxo.svg" style="width:20px" title="Reserva de Luxo"></span>
                                                                      <span style="white-space: nowrap;">
                                                                           {{reserva2.ap}}&nbsp;
                                                                           <span v-if="reserva2.st == 7"></span>
                                                                           <span v-else-if="reserva2.st == 5"><i class="fas fa-cross" style="color:#dc3545;"></i></span>
                                                                           <span v-else-if="reserva2.st == 2"><i class="fas fa-question"></i></span>
                                                                           <span v-else-if="reserva2.st == 3"><img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso"></span>
                                                                           <span v-else-if="reserva2.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar"><i class="fas fa-exclamation" style="color:#dc3545;"></i></span>
                                                                      </span>
                                                                 </td>

                                                                 <td v-if="app_escalar_jogadores[reserva2.id]" class="text-center">
                                                                      <span v-if="app_escalar_jogadores[reserva2.id]['ms'+currentHome]=='ND'" class="small" >indisponível</span>
                                                                      <template v-else>{{app_escalar_jogadores[reserva2.id]['ms'+currentHome]}}</template>
                                                                 </td>
                                                                 <td v-else><span class="small">indisponível</span></td>

                                                                 <td class="text-center">{{reserva2.pr}}</td>

                                                                 <td v-if="app_escalar_jogadores[reserva2.id] && app_escalar_jogadores[reserva2.id][tableVariable]" class="text-center">{{app_escalar_jogadores[reserva2.id][tableVariable]}}</td>
                                                                 <td v-else>&nbsp;</td>

                                                                 <td>
                                                                      <button @click="remove_reserva(reserva2)" class="btn btn-sm btn-remover" style="float:right; width: 60px;">Remover</button>
                                                                 </td>
                                                            </tr>
                                                            <tr v-else>
                                                                 <td colspan="4">Lateral Reserva</td>
                                                                 <td>
                                                                      <button  @click="go_to_table(['2'],true)" class="btn btn-sm btn-reserva r_add" style="float:right; width: 60px;">Reserva</button>
                                                                 </td>
                                                            </tr>
                                                       </template>
                                                       
                                                       <tr v-if="reserva3!=null" :style="[athlete_hover == reserva3.id ? {'background-color':'rgba(0,0,0,.075)'} : {}]"
                                                            @mouseleave="athlete_hover = ''"  @mouseover="athlete_hover = reserva3.id">

                                                            <td style="width: 40%; white-space:nowrap">
                                                                 <small style="color:gray; text-transform:uppercase;">({{positions[reserva3.ps].abreviacao}}) </small>
                                                                 <span v-if="reserva3.id == reserva_luxo_id"><img src="img/reserva_luxo.svg" style="width:20px" title="Reserva de Luxo"></span>
                                                                 <span style="white-space: nowrap;">
                                                                      {{reserva3.ap}}&nbsp;
                                                                      <span v-if="reserva3.st == 7"></span>
                                                                      <span v-else-if="reserva3.st == 5"><i class="fas fa-cross" style="color:#dc3545;"></i></span>
                                                                      <span v-else-if="reserva3.st == 2"><i class="fas fa-question"></i></span>
                                                                      <span v-else-if="reserva3.st == 3"><img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso"></span>
                                                                      <span v-else-if="reserva3.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar"><i class="fas fa-exclamation" style="color:#dc3545;"></i></span>
                                                                 </span>
                                                            </td>

                                                            <td v-if="app_escalar_jogadores[reserva3.id]" class="text-center">
                                                                 <span v-if="app_escalar_jogadores[reserva3.id]['ms'+currentHome]=='ND'" class="small" >indisponível</span>
                                                                 <template v-else>{{app_escalar_jogadores[reserva3.id]['ms'+currentHome]}}</template>
                                                            </td>
                                                            <td v-else><span class="small">indisponível</span></td>

                                                            <td class="text-center">{{reserva3.pr}}</td>

                                                            <td v-if="app_escalar_jogadores[reserva3.id] && app_escalar_jogadores[reserva3.id][tableVariable]" class="text-center">{{app_escalar_jogadores[reserva3.id][tableVariable]}}</td>
                                                            <td v-else>&nbsp;</td>

                                                            <td>
                                                                 <button @click="remove_reserva(reserva3)" class="btn btn-sm btn-remover" style="float:right; width: 60px;">Remover</button>
                                                            </td>
                                                       </tr>
                                                       <tr v-else>
                                                            <td colspan="4">Zagueiro Reserva</td>
                                                            <td>
                                                                 <button  @click="go_to_table(['3'],true)" class="btn btn-sm btn-reserva r_add" style="float:right; width: 60px;">Reserva</button>
                                                            </td>
                                                       </tr>
                                                       
                                                       <tr v-if="reserva4!=null" :style="[athlete_hover == reserva4.id ? {'background-color':'rgba(0,0,0,.075)'} : {}]"
                                                            @mouseleave="athlete_hover = ''"  @mouseover="athlete_hover = reserva4.id">

                                                            <td style="width: 40%; white-space:nowrap">
                                                                 <small style="color:gray; text-transform:uppercase;">({{positions[reserva4.ps].abreviacao}}) </small>
                                                                 <span v-if="reserva4.id == reserva_luxo_id"><img src="img/reserva_luxo.svg" style="width:20px" title="Reserva de Luxo"></span>
                                                                 <span style="white-space: nowrap;">
                                                                      {{reserva4.ap}}&nbsp;
                                                                      <span v-if="reserva4.st == 7"></span>
                                                                      <span v-else-if="reserva4.st == 5"><i class="fas fa-cross" style="color:#dc3545;"></i></span>
                                                                      <span v-else-if="reserva4.st == 2"><i class="fas fa-question"></i></span>
                                                                      <span v-else-if="reserva4.st == 3"><img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso"></span>
                                                                      <span v-else-if="reserva4.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar"><i class="fas fa-exclamation" style="color:#dc3545;"></i></span>
                                                                      
                                                                 </span>
                                                            </td>
                                                            
                                                            <td v-if="app_escalar_jogadores[reserva4.id]" class="text-center">
                                                                 <span v-if="app_escalar_jogadores[reserva4.id]['ms'+currentHome]=='ND'" class="small" >indisponível</span>
                                                                 <template v-else>{{app_escalar_jogadores[reserva4.id]['ms'+currentHome]}}</template>
                                                            </td>
                                                            <td v-else><span class="small">indisponível</span></td>

                                                            <td class="text-center">{{reserva4.pr}}</td>

                                                            <td v-if="app_escalar_jogadores[reserva4.id] && app_escalar_jogadores[reserva4.id][tableVariable]" class="text-center">{{app_escalar_jogadores[reserva4.id][tableVariable]}}</td>
                                                            <td>
                                                                 <button @click="remove_reserva(reserva4)" class="btn btn-sm btn-remover" style="float:right; width: 60px;">Remover</button>
                                                            </td>
                                                       
                                                       </tr>
                                                       <tr v-else>
                                                            <td colspan="4">Meia Reserva</td>
                                                            <td>
                                                                 <button  @click="go_to_table(['4'],true)" class="btn btn-sm btn-reserva r_add" style="float:right; width: 60px;">Reserva</button>
                                                            </td>
                                                       </tr>
                                                       
                                                       <tr v-if="reserva5!=null" :style="[athlete_hover == reserva5.id ? {'background-color':'rgba(0,0,0,.075)'} : {}]"
                                                            @mouseleave="athlete_hover = ''"  @mouseover="athlete_hover = reserva5.id">

                                                            <td v-if="reserva5 != null" style="width: 40%; white-space:nowrap">
                                                                 <small style="color:gray; text-transform:uppercase;">({{positions[reserva5.ps].abreviacao}}) </small>
                                                                 <span v-if="reserva5.id == reserva_luxo_id"><img src="img/reserva_luxo.svg" style="width:20px" title="Reserva de Luxo"></span>
                                                                 <span style="white-space: nowrap;">
                                                                      {{reserva5.ap}}&nbsp;
                                                                      <span v-if="reserva5.st == 7"></span>
                                                                      <span v-else-if="reserva5.st == 5"><i class="fas fa-cross" style="color:#dc3545;"></i></span>
                                                                      <span v-else-if="reserva5.st == 2"><i class="fas fa-question"></i></span>
                                                                      <span v-else-if="reserva5.st == 3"><img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso"></span>
                                                                      <span v-else-if="reserva5.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar"><i class="fas fa-exclamation" style="color:#dc3545;"></i></span>
                                                                 </span>
                                                            </td>

                                                            <td v-if="app_escalar_jogadores[reserva5.id]" class="text-center">
                                                                 <span v-if="app_escalar_jogadores[reserva5.id]['ms'+currentHome]=='ND'" class="small" >indisponível</span>
                                                                 <template v-else>{{app_escalar_jogadores[reserva5.id]['ms'+currentHome]}}</template>
                                                            </td>
                                                            <td v-else><span class="small">indisponível</span></td>

                                                            <td class="text-center">{{reserva5.pr}}</td>

                                                            <td v-if="app_escalar_jogadores[reserva5.id] && app_escalar_jogadores[reserva5.id][tableVariable]" class="text-center">{{app_escalar_jogadores[reserva5.id][tableVariable]}}</td>
                                                            <td v-else>&nbsp;</td>

                                                            <td>
                                                                 <button @click="remove_reserva(reserva5)" class="btn btn-sm btn-remover" style="float:right; width: 60px;">Remover</button>
                                                            </td>
                                                       </tr>
                                                       <tr v-else>
                                                            <td colspan="4">Atacante Reserva</td>
                                                            <td>
                                                                 <button  @click="go_to_table(['5'],true)" class="btn btn-sm btn-reserva r_add" style="float:right; width: 60px;">Reserva</button>
                                                            </td>
                                                       </tr>
                                                  </tbody>
                                             </table>
                                             <div class="row" style="padding-bottom: 6px;">
                                                  <div class="col-6 text-center">
                                                       <button class="btn btn-md btn-verde" 
                                                            @click="send_team_to_cartola()"
                                                            :class="[userTeam.atletas==null || userTeam.atletas.length < 12 || userTeam.capitao_id == '' ? 'btn-outline-light' : 'btn-light']">
                                                            Enviar time: Cartola
                                                       </button>
                                                  </div>
                                                  <div class="col-6 text-center">
                                                       <button class="btn btn-md btn-laranja" 
                                                            @click="send_team_to_backend()"
                                                            :class="[userTeam.atletas==null || userTeam.atletas.length < 12 || userTeam.capitao_id == '' ? 'btn-outline-light' : 'btn-light']">
                                                            Enviar time: Avaliação
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </template>
                              <template v-else>
                                   <div class="col-12 warning" id="login_warning">
                                        <h3 class="text-center" v-if="warning != 'login' && warning != 'time' && warning != 'busca'">
                                        <!-- {{warning}} -->
                                        </h3>
                                        <div v-if="warning != 'login' && warning != 'time' && warning != 'busca'">
                                             <div v-if="!market_status || market_status.status_mercado == 4">
                                                  <mercado-manutencao></mercado-manutencao>
                                             </div>
                                             <div v-else>
                                                  <mercado-fechado></mercado-fechado>
                                             </div>
                                        </div>
                                        <div v-else-if="warning == 'time'">
                                             <div class="text-center">
                                                  <h3 style="font-weight:bolder;">Opa!</h3>
                                                  <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" style="width:150px;"/> <br/><br/>
                                                  <h4 style="color:orange;">Parece que você ainda precisa ativar seu time lá no Cartola...</h4>
                                             </div>
                                        </div>
                                        <div v-else-if="warning == 'busca'">
                                             <div class="text-center">
                                                  <h3 style="font-weight:bolder;">Opa!</h3>
                                                  <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" style="width:150px;"/> <br/><br/>
                                                  <h4 style="color:orange;">Parece que Seu login no Cartola expirou. <br/> Faça login novamente usando o App Guru do Cartola e atualize essa página.</h4>
                                                  
                                             </div>
                                        </div>
                                        <!-- <div v-else>
                                             <h3 class="text-center">
                                                  Você precisa logar para que seu time seja carregado.
                                             </h3>
                                             <hr>
                                             <login :mobile="mobile"></login>
                                        </div> -->
                                   </div>
                              </template>
                         </div>
                    </template>
                    <template v-else>
                         <login :mobile="mobile"></login>
                    </template>
               </div>
               
               <div class="d-flex flex-row"> 
                    <!-- style="margin-top:150px;"> -->
                    <!-- <div class="col-12" style="color:orange;" v-if="mobile">
                         <div class="row" v-if="userTeam != undefined && userTeam.atletas != undefined && mobile && fieldView == 'field' && market_status && market_status.status_mercado == 1 && warning != 'login'">
                              <br>
                              <div class="col-6">
                                   <button class="btn btn-sm btn-verde mb-3" 
                                        @click="send_team_to_cartola()"
                                        :class="[userTeam.atletas.length < 12 || userTeam.capitao_id == '' ? 'btn-outline-light' : 'btn-light']">
                                        Enviar time: Cartola
                                   </button>
                              </div>
                              <div class="col-6">
                                   <button class="btn btn-sm btn-light btn-laranja mb-3" 
                                        @click="send_team_to_backend()"
                                        :class="[userTeam.atletas.length < 12 || userTeam.capitao_id == '' ? 'btn-outline-light' : 'btn-light']">
                                        Enviar time: Avaliação
                                   </button>
                              </div>
                         </div>
                    </div> -->
                    
                    <!-- Tabela Escalação -->
                    <div :class="[mobile ? 'col-12' : 'col-10']" style="padding: 0px;">
                         <div id="main_table" @scroll="increase_view">
                              <table>
                                   <thead>
                                        <th>
                                             <a data-toggle="modal" data-target="#comparison">
                                                  <button 
                                                       class="btn btn-sm btn-success btn-cinza-claro-verde"
                                                       :class="[athletes_to_compare.length < 2 ? 'btn-outline-success' : '']"
                                                       :disabled="athletes_to_compare.length < 2">
                                                       Comparar
                                                  </button>
                                                  <br>
                                                  <button 
                                                       @click="empty_comparison()"
                                                       v-if="athletes_to_compare.length >= 2"
                                                       class="btn btn-sm btn-outline-light"
                                                       style="margin-top:5px;">
                                                       Limpar
                                                  </button>
                                             </a>
                                        </th>
                                        <th colspan = 3>
                                             <span v-if="mobile" style="color:orange;">
                                                  Deslize para o lado ->
                                             </span>
                                        </th>
                                        <th data-toggle="tooltip" data-placement="bottom" title="Avalie e concorra a prêmios. Veja mais em Prêmios do Guru." >
                                             <i class="fas fa-question-circle"></i><br>
                                             É uma boa?<br>
                                        </th>
                                        <th @click="order_by('apv')" data-toggle="tooltip" data-placement="bottom" title="% da guruzada que acha ele uma boa." style="cursor:pointer;">
                                             <i class="fas fa-question-circle"></i><br>
                                             Aprovação<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th @click="order_by('pr')">
                                             Preço<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th @click="order_by('pm')" data-toggle="tooltip" data-placement="bottom" title="A partir desta pontuação o jogador irá começar a valorizar." style="cursor:pointer;">
                                             <i class="fas fa-question-circle"></i><br>
                                             Pont. Mínima<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th style="white-space:nowrap; cursor:pointer;" @click="order_by('pc')" data-toggle="tooltip" data-placement="bottom" title="Pontuação média de outros jogadores da mesma posição que tiveram os mesmos confrontos diretos.">
                                             <i class="fas fa-question-circle"></i><br>
                                             Pont. Cedida<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>                                   
                                        <th @click="order_by('ms')" v-if="currentField==0" style="cursor:pointer;">
                                             Média<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th @click="order_by('pe')" v-if="currentField==1" style="cursor:pointer;">
                                             Pont. Esperada<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th @click="order_by('ve')" v-if="currentField==2" style="cursor:pointer;">
                                             Val. Esperada<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th></th>
                                        <th></th>
                                   </thead>
                                   <tbody>
                                        <template v-for="(athlete) in computed_escalar">
                                             <tr :key="'tr1_'+athlete.id">
                                                  <!-- Checkbox Comparacao -->
                                                  <td>
                                                       <input type="checkbox" 
                                                            :id="'check-' + athlete.id" 
                                                            :checked="athlete.marcado_para_comparacao"
                                                            @click="to_comparison(athlete)"
                                                       >
                                                       <label :for="'check-' + athlete.id" class="check-box"></label>
                                                       <!-- <span>{{athlete.id}}</span> -->
                                                  </td>
                                                  <!-- foto -->
                                                  <td style="z-index:1; background-color:white;">
                                                       <img v-if="athlete.ft" :src="athlete.ft" class="athlete" :title="athlete.ap">
                                                       <img v-if="shirts[athlete.cb]" :src="shirts[athlete.cb].sm" class="shirt">
                                                  </td>
                                                  <!-- Nome e Clubes -->
                                                  <td>
                                                       <div class="row" style="margin-left:10px;">
                                                            <div class="col-12">
                                                                 <p clas="text-center" style="margin:0px; white-space:nowrap;">
                                                                      {{athlete.ap}}
                                                                 </p>
                                                                 <small class="text-left" style="text-transform:uppercase;">
                                                                      ({{positions[athlete.ps].abreviacao}})
                                                                 </small>
                                                            </div>
                                                            <div class="col-6" style="padding:0px;">
                                                                 <img :src="clubs[athlete.lc=='Casa'?athlete.cb:athlete.ca].ec" class="club" :class="athlete.lc=='Casa'?'escudo-clube':''"
                                                                      :title="clubs[athlete.lc=='Casa'?athlete.cb:athlete.ca].nm">
                                                                 &nbsp;
                                                                 <small>
                                                                      {{clubs[athlete.lc=='Casa'?athlete.cb:athlete.ca].cl}}
                                                                 </small>
                                                            </div>
                                                            <div class="col-6" style="padding:0px;">
                                                                 <small>
                                                                     {{clubs[athlete.lc=='Casa'?athlete.ca:athlete.cb].cl}}
                                                                 </small>
                                                                 &nbsp;
                                                                 <img :src="clubs[athlete.lc=='Casa'?athlete.ca:athlete.cb].ec" class="club" :class="athlete.lc=='Casa'?'':'escudo-clube'"
                                                                      :title="clubs[athlete.lc=='Casa'?athlete.ca:athlete.cb].nm">
                                                            </div>
                                                       </div>
                                                  </td>
                                                  <!-- Status -->
                                                  <td style="padding-left:20px;">
                                                       <!-- <span v-if="athlete.st == 7"> --> <!-- Provavel -->
                                                            
                                                       <!-- </span> -->
                                                       <span v-if="athlete.st == 5">
                                                            <i class="fas fa-ambulance" style="color:#dc3545;" title="Machucado"></i>
                                                       </span>
                                                       <span v-else-if="athlete.st == 2">
                                                            <i class="fas fa-question" title="Dúvida para o jogo"></i>
                                                       </span>
                                                       <span v-else-if="athlete.st == 3">
                                                            <img src="img/cartao_vermelho.svg" style="width:20px" title="Suspenso">
                                                       </span>
                                                       <span v-else-if="athlete.st == 6" data-toggle="tooltip" data-placement="top" title="Ele não deve jogar">
                                                            <i class="fas fa-exclamation" style="color:#dc3545;"></i>
                                                       </span>
                                                  </td>
                                                  <!-- E uma Boa -->
                                                  <td class="small_column thumbs">
                                                       <button @click="store_approval(athlete.id, 'like')">
                                                            <i v-if="approvals_on_page['like'].indexOf(athlete.id) != -1" class="fas fa-thumbs-up"></i>
                                                            <i v-else class="far fa-thumbs-up"></i>
                                                       </button>
                                                       &nbsp;
                                                       <button @click="store_approval(athlete.id, 'deslike')">
                                                            <i v-if="approvals_on_page['deslike'].indexOf(athlete.id) != -1" class="fas fa-thumbs-down"></i>
                                                            <i v-else class="far fa-thumbs-down"></i>
                                                       </button>
                                                  </td>
                                                  <!-- Aprovacao -->
                                                  <td> 
                                                       {{ approvals[athlete.id] != undefined  
                                                       && (approvals[athlete.id].lik + approvals[athlete.id].dlk >= 5)  
                                                       ? approvals[athlete.id].apv+'%' : '?' }} 
                                                  </td>
                                                  <!-- Preco -->
                                                  <td>
                                                       {{athlete.pr}}
                                                  </td>
                                                  <!-- Pontuacao Minima -->
                                                  <td>
                                                       {{athlete.pm}}
                                                  </td>
                                                  <!-- Pontuacao Cedida -->
                                                  <td>
                                                       <span v-if="Math.abs(athlete.pc) != 100.00">
                                                            {{athlete.pc}}
                                                       </span>
                                                       <span v-else>
                                                            <small>
                                                                 Indisponível
                                                            </small>
                                                       </span>
                                                  </td>
                                                  <!-- * Media -->
                                                  <td v-if="currentField==0">
                                                       <span v-if="Math.abs(athlete.ms) != 100.00">
                                                            {{athlete.ms}} 
                                                       </span>
                                                       <span v-else>
                                                            <small>
                                                                 Indisponível
                                                            </small>
                                                       </span>
                                                  </td>
                                                  <!-- * Pontuacao Esperada -->
                                                  <td v-if="currentField==1">
                                                       <span v-if="Math.abs(athlete.pe) != 100.00">
                                                            {{athlete.pe}} 
                                                       </span>
                                                       <span v-else>
                                                            <small>
                                                                 Indisponível
                                                            </small>
                                                       </span>
                                                  </td>
                                                  <!-- Valorizacao Esperada -->
                                                  <td v-if="currentField==2">
                                                       <span v-if="Math.abs(athlete.ve) != 100.00">
                                                            {{athlete.ve}} 
                                                       </span>
                                                       <span v-else>
                                                            <small>
                                                                 Indisponível
                                                            </small>
                                                       </span>
                                                  </td>
                                                  <!-- Botoes -->
                                                  <td>
                                                       <template v-if="filtro_reservas">
                                                            <button v-if="getMenorPreco(athlete.ps) < athlete.pr" class="btn btn-sm btn-outline-primary" 
                                                                 @click="alerta('Jogador muito caro.','O preço do reserva não pode ser maior que o titular mais barato da posição.','warning')"
                                                                 style="margin-right:10px">
                                                                 Mais Caro
                                                            </button>
                                                            <button v-else-if="isTitular(athlete.id)" class="btn btn-sm btn-outline-primary" 
                                                                 @click="alerta('Jogador Titular.','Este jogador já está no seu time titular.','warning')"
                                                                 style="margin-right:10px; min-width:67px;">
                                                                 Titular
                                                            </button>
                                                            <button v-else class="btn btn-sm btn-primary" 
                                                                 style="margin-right:10px; min-width:67px;"
                                                                 @click="addReserva(athlete)">
                                                                 Reserva
                                                            </button>
                                                       </template>
                                                       <template v-else>
                                                            <button v-if="athlete.incluso_no_time" class="btn btn-sm btn-outline-danger" 
                                                                 style="margin-right: 10px"
                                                                 @click="sell_athlete(athlete)">
                                                                 Vender
                                                            </button>
                                                            <button v-else class="btn btn-sm" 
                                                                 style="margin-right: 10px"
                                                                 :class="[!(remaining_positions[positions[athlete.ps].abreviacao] > 0) || (get_userTeam_total_price() + athlete.pr >userTeam.patrimonio) ? 'btn-outline-primary' : 'btn-primary']"
                                                                 @click="buy_athlete(athlete)">
                                                                 Comprar<br>
                                                            </button>
                                                       </template>
                                                       
                                                  </td>
                                                  <td v-if="athlete.ps != 6" @click="[moreInfo == athlete.id ? moreInfo = '' : moreInfo = athlete.id]" style="vertical-align: bottom">
                                                       <div :class="{'info-enabled':athlete.af == 0 || (user != null && user.membro == 1)}">
                                                            <p v-if="moreInfo == athlete.id">
                                                                 <i class="fas fa-minus"></i><br>
                                                                 Info
                                                            </p>
                                                            <p v-else @click="adjust_table_to_left(athlete.id)">
                                                                 <i class="fas fa-plus"></i><br>
                                                                 Info
                                                            </p>
                                                       </div>
                                                  </td>
                                                  <td v-else>
                                                       &nbsp;
                                                  </td>
                                             </tr>
                                             <tr :key="'tr2_'+athlete.id"> 
                                                  <td v-if="moreInfo == athlete.id" colspan = 12 style="background-color: rgb(249, 249, 249)">
                                                       <DetalhaJogador :user="user" :mobile="mobile" :market_status="market_status" :rodadas="currentRound"
                                                            :athlete="athlete" :clubes="todosClubes" :mandoCampo="currentHome"
                                                             > <!-- :scouts="player_scouts"-->
                                                       </DetalhaJogador>
                                                  </td>
                                             </tr>
                                        </template>
                                   </tbody>
                              </table>
                         </div>
                    </div>

                    <!-- Filtros Escalação -->
                    <div v-if="!mobile" class="col-2" style="padding: 0px;">
                         <filters :mobile="false" :clubs="todosClubes" :currentPos="currentPos" :currentStatus="currentStatus" @changedHome="currentHome = $event" 
                         @changedRound="currentRound = $event" @changedSearch="search = $event" @changedCurrentClub="currentClub = $event" @changedCurrentPos="currentPos = $event" 
                         @changedCurrentStatus="currentStatus = $event" @changedField="currentField = $event"></filters>
                    </div>

                    <!-- Filtros Escalação [Mobile]-->
                    <div v-else id="mobile_filter">
                         <a data-toggle="modal" data-target="#filters" id="activate_modal" @click="$('#myModal').modal()">
                              <i class="fas fa-question-circle"></i> Filtros
                              <span v-if="currentClub.length > 0">
                                   <i class="fas fa-filter"></i>
                              </span>
                         </a>
                         <div class="modal fade" id="filters" tabindex="-1" role="dialog" aria-labelledby="filter" aria-hidden="true">
                              <div class="modal-dialog modal-sm" role="document">
                                   <div class="modal-content">
                                        <div class="modal-header">
                                             <h5 class="modal-title" id="filter">Filtrar Jogadores</h5>
                                             <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset_filters()">
                                             <span aria-hidden="true">&times;</span>
                                             </button>
                                        </div>
                                        <div class="modal-body">
                                             <filters :mobile="true" :currentPos="currentPos" :currentStatus="currentStatus" :clubs="clubs" @changedHome="currentHome = $event" @changedRound="currentRound = $event" @changedSearch="search = $event" @changedCurrentClub="currentClub = $event" @changedCurrentPos="currentPos = $event" @changedCurrentStatus="currentStatus = $event"></filters>
                                             <div class="text-center">
                                                  <br>
                                                  <button class="btn bnt-md btn-dark" type="button" data-dismiss="modal" aria-label="Close">
                                                       Filtrar
                                                  </button>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <!-- Comparação Atletas -->
                    <div class="modal fade" id="comparison" tabindex="-1" role="dialog" aria-labelledby="comparison" aria-hidden="true">
                         <div class="modal-dialog modal-lg" role="document">
                              <div class="modal-content">
                                   <div class="modal-header">
                                        <h5 class="modal-title">
                                             Comparação de jogadores ({{athletes_to_compare.length}})
                                        </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                   </div>
                                   <div class="modal-body">
                                        <div v-if="user != null && user.membro == 1" id="comparison_table">
                                             <table class="table table-borderless"
                                                  :style="[athletes_to_compare.length == 2 ? {'width':'75%'} : {'width':'100%'}]">
                                                  <thead>
                                                       <tr>
                                                            <th :style="[athletes_to_compare.length == 2 ? {'width':'50%'} : {'width':'30%'}]"></th>
                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CPOS'+n">
                                                                 <img 
                                                                      :src="athlete.ft" 
                                                                      :alt="'Foto Jogador '+ athlete.ap"
                                                                      :style="[mobile ? {'width':'50px'} : {}]">
                                                                 <p style="white-space: nowrap">
                                                                      {{athlete.ap}}<br>
                                                                      <small>({{positions[athlete.ps].nome}})</small>
                                                                 </p>
                                                            </td>
                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       <tr>
                                                            <th>Preço</th>
                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CPRE'+n"> 

                                                                 <div id="graph">
                                                                      <div id="noFill"
                                                                           :style="{'height' : 100 * (1 - athlete.pr/21) + '%'}">
                                                                      </div>
                                                                      <div id="fill" :style="{'height' : 100 * (athlete.pr/21) + '%'}" back> 
                                                                      </div>
                                                                      <p class="text-center">
                                                                           {{athlete.pr}}
                                                                      </p> 
                                                                 </div>
                                                                 
                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th>Média</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CMED'+n">
                                                                 
                                                                 <div id="graph" v-if="Math.abs(athlete.ms) != 100.00">
                                                                      <div id="noFill"
                                                                           :style="{'height' : 100 * (1 - athlete.ms/10) + '%'}">
                                                                      </div>
                                                                      <div id="fill" :style="{'height' : 100 * (athlete.ms/10) + '%'}" back>
                                                                      </div>
                                                                      <p class="text-center">
                                                                           {{athlete.ms}}
                                                                      </p>
                                                                 </div>
                                                                 <div v-else>
                                                                      <small>Indisponível</small>
                                                                 </div>

                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th scope="row">Pontuação Cedida</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CPCE'+n">
                                                                 
                                                                 <div id="graph" v-if="Math.abs(athlete.pc) != 100.00">
                                                                      <div id="noFill"
                                                                           :style="{'height' : 100 * (1 - athlete.pc/10) + '%'}">
                                                                      </div>
                                                                      <div id="fill" :style="{'height' : 100 * (athlete.pc/10) + '%'}" back>
                                                                      </div>
                                                                      <p class="text-center">
                                                                           {{athlete.pc}}
                                                                      </p>
                                                                 </div>
                                                                 <div v-else>
                                                                      <small>Indisponível</small>
                                                                 </div>

                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th>Pont. mínima p/ valorizar</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CPMI'+n">
                                                                 
                                                                 <div id="graph">
                                                                      <div id="noFill"
                                                                           :style="{'height' : 100 * (1 - athlete.pm/21) + '%'}">
                                                                      </div>
                                                                      <div id="fill" :style="{'height' : 100 * (athlete.pm/21) + '%'}" back>
                                                                      </div>
                                                                      <p class="text-center">
                                                                           {{athlete.pm}}
                                                                      </p>
                                                                 </div>

                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th>Aprovação da Guruzada</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CAPV'+n">
                                                                
                                                                 <div id="graph">
                                                                      <template v-if="approvals[athlete.id]">
                                                                           <div id="noFill" :style="{'height' : 100 * (1 - approvals[athlete.id].apv/100) + '%'}"></div>
                                                                           <div id="fill" :style="{'height' : 100 * (approvals[athlete.id].apv/100) + '%'}" back></div>
                                                                           <p class="text-center">
                                                                                {{approvals[athlete.id].apv}}%
                                                                           </p>
                                                                      </template>
                                                                      <template v-else>
                                                                           <div id="noFill" style="height:50%;"></div>
                                                                           <div id="fill" style="height:50%;" back></div>
                                                                           <p class="text-center">
                                                                                50%
                                                                           </p>
                                                                      </template>
                                                                 </div>

                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th>Ritmo de jogo</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CRTJ'+n">
                                                                 <span>
                                                                      {{athlete.rj}}
                                                                 </span>
                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th>Adversário</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CADV'+n">
                                                                 <span>
                                                                      {{clubs[athlete.ca].nm}}
                                                                 </span>
                                                            </td>
                                                       </tr>
                                                       <tr>
                                                            <th>Mando de Campo</th>

                                                            <td v-for="(athlete,n) in athletes_to_compare" :key="'CMAN'+n">
                                                                 <span>
                                                                      {{athlete.lc}}
                                                                 </span>
                                                            </td>
                                                       </tr>
                                                       
                                                  </tbody>
                                             </table>
                                        </div>
                                        <div v-else class="text-center" :style="[mobile ? {'width':0.9*windowWidth+'px'} : {}]">
                                             <div class="swal2-icon swal2-warning swal2-animate-warning-icon" style="display: flex;"><span class="swal2-icon-text">!</span></div><br>
                                             <h6>
                                                  Essa função é exclusiva para quem faz parte do Time Guru.
                                             </h6><br>
                                             <a :href="selling_page">
                                                  <button class="btn btn-md btn-success">
                                                       QUERO SER TIME GURU
                                                  </button>
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <!-- Restrição Campinho -->
                    <!-- <RestricaoCampinho :computed_escalar="computed_escalar" :show="showRestricaoCampinho"></RestricaoCampinho> -->
                    <div class="modal fade" id="restrictions" tabindex="-1" role="dialog" aria-labelledby="restrictions" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                         <div class="modal-dialog modal-md" role="document">
                              <div class="modal-content">
                                   <div class="modal-header">
                                        <h5>Mudança de formação</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="change_tatic('close')">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                   </div>
                                   <div class="modal-body">
                                        <div id="main_table_restrictions" v-if="messages.length > 0">
                                             <p>
                                                  <ul>
                                                       <li v-for="(message,n) in messages" :key="'MES'+n">
                                                            {{message}}
                                                       </li>
                                                  </ul>
                                             </p>
                                             <!-- <table>
                                                  <thead>
                                                       <th></th>
                                                       <th></th>
                                                       <th @click="order_by('apv')">
                                                            Aprovação<br>
                                                            <i class="fas fa-caret-up"></i>
                                                            <i class="fas fa-caret-down"></i>
                                                       </th>
                                                       <th @click="order_by('pr')">
                                                            Preço<br>
                                                            <i class="fas fa-caret-up"></i>
                                                            <i class="fas fa-caret-down"></i>
                                                       </th>
                                                       <th @click="order_by('pm')">
                                                            Pont. Mínima<br>
                                                            <i class="fas fa-caret-up"></i>
                                                            <i class="fas fa-caret-down"></i>
                                                       </th>
                                                       <th @click="order_by('pc')">
                                                            Pont. Cedida<br>
                                                            <i class="fas fa-caret-up"></i>
                                                            <i class="fas fa-caret-down"></i>
                                                       </th>
                                                       <th @click="order_by('ms'+currentHome)">
                                                            Média<br>
                                                            <i class="fas fa-caret-up"></i>
                                                            <i class="fas fa-caret-down"></i>
                                                       </th>
                                                       <th></th>
                                                  </thead>
                                                  <tbody>
                                                       <tr v-for="(athlete,n) in computed_escalar" :key="'CE'+n">
                                                            <td style="z-index:1; background-color:white;">
                                                                 <img v-if="athlete.foto" :src="athlete_photo(athlete.ft)" class="athlete">
                                                                 <img :src="shirts[athlete.cb].sm" class="shirt">
                                                            </td>
                                                            <td>
                                                                 <div class="row large_column">
                                                                      <div class="col-12">
                                                                           <p clas="text-center" style="margin:0px">
                                                                                {{athlete.ap}}
                                                                           </p>
                                                                           <small class="text-left" style="text-transform:uppercase;">
                                                                                ({{positions[athlete.ps].abreviacao}})
                                                                           </small>
                                                                      </div>
                                                                      <div class="col-6" style="padding:0px;">
                                                                           <img :src="athlete.ec" class="club">
                                                                           &nbsp;
                                                                           <small>
                                                                                {{athlete.cl}}
                                                                           </small>
                                                                      </div>
                                                                      <div class="col-6" style="padding:0px;">
                                                                           <small>
                                                                                {{athlete.ca}}
                                                                           </small>
                                                                           &nbsp;
                                                                           <img :src="athlete.ef" class="club">
                                                                      </div>
                                                                 </div>
                                                            </td>
                                                            <td> 
                                                                 {{athlete.aprovacao}} %
                                                            </td>
                                                            <td>
                                                                 {{athlete.pr}}
                                                            </td>
                                                            <td>
                                                                 {{athlete.pm}}
                                                            </td>
                                                            <td>
                                                                 <span v-if="Math.abs(athlete.pc) != 100.00">
                                                                      {{athlete.pc}}
                                                                 </span>
                                                                 <span v-else>
                                                                      <small>
                                                                           Indisponível
                                                                      </small>
                                                                 </span>
                                                            </td>
                                                            <td>
                                                                 <span v-if="Math.abs(athlete.ms) != 100.00">
                                                                      {{athlete.ms}}
                                                                 </span>
                                                                 <span v-else>
                                                                      <small>
                                                                           Indisponível
                                                                      </small>
                                                                 </span>
                                                            </td>
                                                            <td>
                                                                 <template v-if="filtro_reservas">
                                                                      <button class="btn btn-sm btn-outline-primary" 
                                                                           style="margin-right: 10px"
                                                                           @click="dosomething(athlete)">
                                                                           Reserva
                                                                      </button>
                                                                 </template>
                                                                 <template v-else>
                                                                      <button v-if="athlete.incluso_no_time" class="btn btn-sm btn-outline-danger" 
                                                                           style="margin-right: 10px"
                                                                           @click="sell_athlete(athlete)">
                                                                           Vender
                                                                      </button>
                                                                      <button v-else class="btn btn-sm btn-outline-primary" 
                                                                           style="margin-right: 10px"
                                                                           @click="buy_athlete(athlete)">
                                                                           Comprar
                                                                      </button>
                                                                 </template>
                                                            </td>
                                                       </tr>
                                                  </tbody>
                                             </table> -->
                                        </div>
                                        <div v-else class="text-center">
                                             <p>
                                                  A formação do seu time foi alterada com sucesso!
                                             </p>
                                             <button type="button" class="btn btn-md btn-success" data-dismiss="modal" aria-label="Close" @click="change_tatic('done')">
                                                  Prosseguir
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <!-- Mapa de Calor -->
                    <div class="modal fade" id="heatmapModal" tabindex="-1" role="dialog" aria-labelledby="heatmapModal" aria-hidden="true">
                         <div class="modal-dialog modal-md" role="document">
                              <div class="modal-content">
                                   <div class="modal-header">
                                        <h5 class="modal-title" v-if="moreInfo != ''">
                                             Mapa de calor
                                        </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                   </div>
                                   <div class="modal-body text-center">
                                        <HeatMapModal ref="heatMap" :athlete_id="heatmap_athlete_id" :round="heatmap_round"></HeatMapModal>
                                   </div>
                              </div>
                         </div>
                    </div>
                    
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import login from '@/components/login.vue';
     import filters from '@/components/filters.vue';
     import DetalhaJogador from '@/components/DetalhaJogador.vue';
     import HeatMapModal from '@/components/HeatMapModal.vue';
     import MercadoManutencao from '@/components/MercadoManutencao.vue';
     import MercadoFechado from '@/components/MercadoFechado.vue';
     // import RestricaoCampinho from '@/components/RestricaoCampinho.vue';
     // import Banner from '@/components/Banner.vue';

     //import clubs_json from '@/json/clubes.json';
     import shirts_json from '@/json/shirts.json';
     import positions_json from '@/json/positions_new.json';

     import util from '@/mixin/util';
     import security from '@/mixin/security';
     

     export default {
          name: 'Escalacao',
          components:{
               customHeader, filters, login, DetalhaJogador, HeatMapModal, MercadoManutencao, MercadoFechado
                    // RestricaoCampinho, Banner
          },
          props: {
               user: {
                    required: true
               }
          },
          
          mixins: [util, security],
          data(){
               return{
                    //Dados
                    // user:null,
                    app_escalar_jogadores:[],
                    app_escalar:[],
                    // app_scouts:[],
                    player_scouts:[],
                    athletes:[],
                    status:[],
                    userTeam:[],
                    approvals:[],
                    approvals_on_page:{
                         "like":[],
                         "deslike":[]
                    },
                    tableVariable:'pm',
                    orderBy: 'apv',
                    // isDataLoaded: false,
                    showRestricaoCampinho: false,
                    reserva1: null,
                    reserva2: null,
                    reserva3: null,
                    reserva4: null,
                    reserva5: null,

                    reserva_luxo_id: 0,

                    //Mock
                    clubs: [], //clubs_json,
                    todosClubes: [], 
                    shirts: shirts_json,
                    positions: positions_json,

                    //Status do mercado via API do CARTOLA
                    market_status:null,

                    //Mapas de calor
                    hm_json:[],
                    heatmap_coordinates:[],                 

                    //Lógica
                    tactics:[
                         {"esquema_id":0,"nome":"3-4-3","posicoes":{"gol":1,"lat":0,"zag":3,"mei":4,"ata":3,"tec":1}},
                         {"esquema_id":1,"nome":"3-5-2","posicoes":{"gol":1,"lat":0,"zag":3,"mei":5,"ata":2,"tec":1}},
                         {"esquema_id":2,"nome":"4-3-3","posicoes":{"gol":1,"lat":2,"zag":2,"mei":3,"ata":3,"tec":1}},
                         {"esquema_id":3,"nome":"4-4-2","posicoes":{"gol":1,"lat":2,"zag":2,"mei":4,"ata":2,"tec":1}},
                         {"esquema_id":4,"nome":"4-5-1","posicoes":{"gol":1,"lat":2,"zag":2,"mei":5,"ata":1,"tec":1}},
                         {"esquema_id":5,"nome":"5-3-2","posicoes":{"gol":1,"lat":2,"zag":3,"mei":3,"ata":2,"tec":1}},
                         {"esquema_id":6,"nome":"5-4-1","posicoes":{"gol":1,"lat":2,"zag":3,"mei":4,"ata":1,"tec":1}}
                    ],
                    desired_tactic:0,//esquema_id escolhido para trocar
                    messages:[],//Mensagens que aparecem na situação de compra/venda
                    remaining_positions:{//Quantidade restante em cada posição
                         "gol":0,
                         "lat":0,
                         "zag":0,
                         "mei":0,
                         "ata":0,
                         "tec":0
                    },
                    to_be_rebought:[],//Atletas para serem recomprados na situação que houver restrições na troca de esquema
                    athlete_hover:'',//Em qual atleta o hover se encontra no momento                    
                    athletes_to_compare:[],//Atletas para comparação
                    userTeamChanges:false,//Ocorreram mudanças no time do usuário?
                    laterais:0,//Laterais

                    somaMedia:0,
                    somaPreco:0,
                    somaPontMin:0,

                    //Filtros
                    search:'',
                    currentSort:'',
                    currentClub:[],
                    currentPos:[],
                    currentStatus:['7'],
                    currentRound:'10',
                    currentHome:0,allHomeFilter:0,
                    currentOrder:-1,
                    currentPage:1,
                    pageSize:25,
                    filterField: 0,
                    currentField: 0,
                    filtro_reservas: false,


                    //Auxliar
                    teams_gifs:[262,263,264,265,266,267,275,276,277,282,283,284,285,293],//id de times para os quais tem-se gif de chamada para assinar o guru
                    wpp_group_link:'',
                    warning:'',//Avisos
               
                    //'both' => Campinho + Tabela. 'field' => Campinho. 'table' => Tabela.
                    fieldView:'both',

                    //Visualizando mais informações de qual atleta?
                    moreInfo:'',

                    heatmap_athlete_id: '',
                    heatmap_round: '',

                    showBanner: false,

                    //Verificação mobile
                    windowWidth:0,
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          watch:{
               currentRound: function (newValue) {
                    var vm = this;
                    this.$http
                    // .get(this.$_PATH+'/escalar/rodadas_anteriores/mobile/secure/'+newValue)
                    .get(this.$API+'/escalar/rodadas_anteriores/'+newValue)
                    .then(response => {
                         // var retorno = JSON.parse(vm.aesDencrypt(response.data));
                         var retorno = response.data;
                         vm.app_escalar = Object.values(retorno.jogadores);
                         vm.app_escalar_jogadores = retorno.jogadores;
                         vm.clubs =  retorno.clubes;
                    })
                    .catch(function (error) {
                         // eslint-disable-next-line no-console
                         console.log(error);
                    }); 
               },
              'userTeam.atletas': function(){
                    this.somaMedia = 0;
                    this.somaPreco = 0;
                    this.somaPontMin = 0;
                    if(this.userTeam.atletas.length != []){
                         this.desired_tactic = this.userTeam.time.esquema_id - 1;
                         this.userTeam.atletas.forEach(athlete => {
                             if(this.app_escalar_jogadores[athlete.id]) {
                                  if (this.app_escalar_jogadores[athlete.id]['ms'+this.currentHome] != 'ND') {
                                   var media = Number(this.app_escalar_jogadores[athlete.id]['ms'+this.currentHome]);
                                   if (this.userTeam.capitao_id == athlete.id) {
                                        media *= 2;
                                   }
                                   this.somaMedia += media;
                                  }
                                  if (this.app_escalar_jogadores[athlete.id][this.tableVariable] != 'ND') {
                                   this.somaPontMin += Number(this.app_escalar_jogadores[athlete.id][this.tableVariable]);
                                  }
                             }
                             this.somaPreco += athlete.pr;
                         }); 
                    }
               },
               market_status: function(newValue){
                    
                    if(newValue){
                         var vm = this;

                         //Carregando o time do usuário
                         this.get_user_team();

                         //Qual o status atual do mercado?
                         if(this.market_status.status_mercado == 4){
                              this.warning = 'Mercado em manutenção';
                         }
                         if(this.warning!='login' && this.market_status.status_mercado == 2){
                              this.warning = 'Mercado fechado';
                         }

                         //Carregando aprovações
                         this.$http
                         // .get(this.$_PATH+'/aprovacao/')
                         .get(this.$API+'aprovacao/')
                         .then(response => {
                              vm.approvals = response.data
                         })
                         .catch(function (error) {
                              // eslint-disable-next-line no-console
                              console.log(error);
                         }); 

                         //Carrega os atletas + aprovações na página de atletas votados pelo usuário
                         this.$http
                         // .get(this.$_PATH+'/escalar/rodadas_anteriores/mobile/secure/'+this.currentRound)
                         .get(this.$API+'escalar/rodadas_anteriores/'+this.currentRound)
                         .then(response => {
                              // var retorno = JSON.parse(vm.aesDencrypt(response.data));
                              // var retorno = JSON.parse(response.data);
                              var retorno = response.data
                              
                              vm.app_escalar = Object.values(retorno.jogadores);
                              vm.app_escalar_jogadores = retorno.jogadores;
                              vm.clubs =  retorno.clubes;
                         })
                         .catch(function (error) {
                              // eslint-disable-next-line no-console
                              console.error(error);
                         }); 

                         if (vm.user != null) {
                              this.$http
                              // .get(this.$_PATH+'/aprovacao/user/'+vm.user.uuid)
                              .get(this.$API+'aprovacao/user/'+vm.user.uuid)
                              .then(response => {
                                   vm.approvals_on_page = response.data
                              })
                              .catch(function (error) {
                                   // eslint-disable-next-line no-console
                                   console.error(error);
                              }); 
                         }


                    }
               }, 
               tableVariable: function(newValue) {
                    this.somaMedia = 0;
                    this.somaPreco = 0;
                    this.somaPontMin = 0;
                    if(this.userTeam.atletas.length != []){
                         this.userTeam.atletas.forEach(athlete => {
                             if(this.app_escalar_jogadores[athlete.id]) {
                                  if (this.app_escalar_jogadores[athlete.id]['ms'+this.currentHome] != 'ND') {
                                        var media = Number(this.app_escalar_jogadores[athlete.id]['ms'+this.currentHome]);
                                        if (this.userTeam.capitao_id == athlete.id) {
                                             media *= 1.5;
                                        }
                                        this.somaMedia += media;
                                  }
                                  if (this.app_escalar_jogadores[athlete.id][newValue] != 'ND') {
                                   var novoValor = Number(this.app_escalar_jogadores[athlete.id][newValue]);
                                   if (newValue == 'pe' && this.userTeam.capitao_id == athlete.id) {
                                        novoValor *= 1.5;
                                   }
                                   this.somaPontMin += novoValor;
                                  }
                             }
                             this.somaPreco += athlete.pr;
                         }); 
                    }
               }
               // allHomeFilter: function(){
               //      if (this.moreInfo) {
               //           this.adjust_table_to_left(this.moreInfo);
               //      }
               // }
          },
          created(){
               //Definindo aviso base
               this.warning = 'Carregando o seu time...';

               this.windowWidth = window.innerWidth;

               //windowWidth defining mobile and fieldView
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
               (window.innerWidth < this.mobileAt) ? this.fieldView = 'field' : this.fieldView = 'both'

               //Scroller to activate/desactivate mobile filter
               $(window).scroll(function() {
                    if($(window).scrollTop() >= 0.5 * $(window).height()) {
                         $("#activate_modal").show();
                    }else{
                         $("#activate_modal").hide();
                    }
               });

               var vm = this;

               //market_status
               this.$http
               // .get(this.$_PATH+'/mercado/status')
               .get(this.$API+'mercado-status')
               .then(response => {
                    vm.market_status = response.data
               })
               .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
               }); 

               //wpp_group_link
               this.$http
               .get('https://gurudocartola.com/wpp_group.txt')
               .then(response => {
                    vm.wpp_group_link = response.data
               })
               .catch(function (error) {
                     vm.wpp_group_link = error
               }); 

               //todos os clubes
               this.$http
               // .get(this.$_PATH+'/info-clubes/')
               .get(this.$API+'info-clubes/')
               .then(response => {
                    vm.todosClubes = response.data
               })
               .catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
               }); 

               if(vm.user){
                    localStorage.removeItem('userTeam');

                    if(localStorage.getItem('userTeam')){
                         vm.userTeam = JSON.parse(localStorage.getItem('userTeam'));
                         vm.gerReservas(vm.userTeam.reservas);
                         
                    }else if(vm.market_status && vm.market_status.status_mercado == 1){
                         vm.get_user_team();
                    }

                    if(vm.user.membro == 1){
                         if (vm.user.membro == 1) {
                              vm.currentHome = 1;
                              vm.allHomeFilter = 1;
                         } else {
                              vm.currentHome = 0;
                              vm.allHomeFilter = 0;
                         }
                    } 
                    
                    
                    setTimeout(function(){
                         if (vm.userTeam.time != null) {
                         vm.desired_tactic = vm.userTeam.time.esquema_id - 1;
                         }
                         if(vm.userTeam == null || vm.userTeam.atletas == null || vm.userTeam.atletas.length == 0){
                              // eslint-disable-next-line no-undef
                              Swal.fire('Seu time não possui jogadores!','Para iniciar a escalação de seu time, selecione uma formação.','warning');
                         }
                    },15000);
               }else{
                    vm.warning = 'login';
                    
               }

               //Banner
               if (!(this.user && this.user.membro == 1)) {
                   vm.showBanner = true; 
               }

               ///Aviso depois de 90 segundos
               if(vm.user == null){
                    setTimeout(function(){
                         // eslint-disable-next-line no-undef
                         Swal.fire({
                              title: 'Se Liga!',
                              text: "Você está perdendo a chance de escalar o seu time no Cartola enquanto analisa quais são os melhores jogadores! Não fique para trás! É só fazer Login!",
                              type: 'warning',
                              showCancelButton: false,
                              confirmButtonColor: '#28a745',
                              confirmButtonText: 'Quero escalar AGORA',
                         }).then((result) => {
                              if (result.value){
                                   $([document.documentElement, document.body]).animate({
                                        scrollTop: $("#login_warning").offset().top
                                   }, 1000);
                              }
                         });
                    },90000);
               }

               setTimeout(function(){
                    $('[data-toggle="tooltip"]').tooltip();
               },5000);
          },
          methods:{
               esquemaPossuiLateral() {
                    if (this.desired_tactic != null && this.desired_tactic!=undefined) {
                         if(this.desired_tactic == 0 || this.desired_tactic == 1){
                              return false;
                         }
                    }
                    return true;
               },
               getClassJogador(atleta, reserva) {
                   var retorno = '';
               //     console.log(atleta.ap + ' | ' + reserva + ' | ' + this.isTitular(atleta.id) + ' | ' + this.reservaHasError(atleta.ps) )
                   if (reserva) {
                        if (this.reservaHasError(atleta.ps)) {
                              retorno += " jogador-error";
                        } else  if (this.reservaHasWarn(atleta.ps)) {
                              retorno += " jogador-warning";     
                        }
                   } else if (atleta.st != 7) {
                        retorno += " jogador-warning";
                   }
                   return retorno;
               },
               alerta(titulo, texto, tipo) {
                    // eslint-disable-next-line no-undef
                    Swal.fire(titulo,texto,tipo);
               },
               getMenorPreco(posicao){
                    var menorPreco = 9999;
                    this.userTeam.atletas.forEach(a => {
                         if (a.ps == posicao && a.pr < menorPreco) {
                              menorPreco = a.pr;
                         }
                    });
                    return menorPreco;
               },
               isTitular(idAtleta) {
                    var retorno = false;
                    this.userTeam.atletas.forEach(a => {
                         if (a.id == idAtleta) {
                              retorno = true;
                         }
                    });
                    return retorno;
               },
               addReserva(atleta) {
                    switch (atleta.ps) {
                         case 1:
                              this.reserva1 = atleta;
                              this.userTeamChanges = true;
                              break;
                         case 2:
                              this.reserva2 = atleta;
                              this.userTeamChanges = true;
                              break;
                         case 3:
                              this.reserva3 = atleta;
                              this.userTeamChanges = true;
                              break;
                         case 4:
                              this.reserva4 = atleta;
                              this.userTeamChanges = true;
                              break;
                         case 5:
                              this.reserva5 = atleta;
                              this.userTeamChanges = true;
                              break;
                    }
               },
               getReservas(lista) {
                    lista.forEach(a => {
                         switch(a.ps) {
                              case 1:
                                   this.reserva1 = a;
                                   break;
                              case 2:
                                   this.reserva2 = a;
                                   break;
                              case 3:
                                   this.reserva3 = a;
                                   break;
                              case 4:
                                   this.reserva4 = a;
                                   break;
                              case 5:
                                   this.reserva5 = a;
                                   break;
                         }
                    })
               },
               reservaHasError(resPos) {
                    var athlete = null;
                    switch(resPos) {
                         case 1: athlete = this.reserva1; break;
                         case 2: athlete = this.reserva2; break;
                         case 3: athlete = this.reserva3; break;
                         case 4: athlete = this.reserva4; break;
                         case 5: athlete = this.reserva5; break;
                    }
                    if (athlete!=null && (this.getMenorPreco(resPos) < athlete.pr || this.isTitular(athlete.id))) {
                         return true;
                    }
                    return false;
               },
               reservaHasWarn(resPos) {
                    var athlete = null;
                    switch(resPos) {
                         case 1: athlete = this.reserva1; break;
                         case 2: athlete = this.reserva2; break;
                         case 3: athlete = this.reserva3; break;
                         case 4: athlete = this.reserva4; break;
                         case 5: athlete = this.reserva5; break;
                    }
                    if (athlete!=null && athlete.st != 7) {
                         return true;
                    }
                    return false;
               },
               //transforma cada atleta da lista de userTeam.atletas para a nomenclatura reduzida
               parse_atletas_userTeam() {
                    var novosAtletas = [];
                    this.userTeam.atletas.forEach(element => {
                         var a = {};
                         a.nm = element.nome;
                         a.slug = element.slug;
                         a.ap = element.apelido;
                         a.ft = element.foto;
                         a.id = element.atleta_id;
                         a.rodada_id = element.rodada_id;
                         a.ps = element.posicao_id;
                         a.st = element.status_id;
                         a.pontos_num = element.pontos_num;
                         a.pr = element.preco_num;
                         a.variacao_num = element.variacao_num;
                         a.media_num = element.media_num;
                         a.jogos_num = element.jogos_num;
                         a.scout = element.scout;
                         novosAtletas.push(a);
                    });
                    this.userTeam.atletas = novosAtletas;
               },
               //transforma cada atleta da lista de userTeam.atletas para a nomenclatura reduzida
               parse_reservas_userTeam() {
                    var novosAtletas = [];
                    if (this.userTeam.reservas) {
                         this.userTeam.reservas.forEach(element => {
                              var a = {};
                              a.nm = element.nome;
                              a.slug = element.slug;
                              a.ap = element.apelido;
                              a.ft = element.foto;
                              a.id = element.atleta_id;
                              a.rodada_id = element.rodada_id;
                              a.ps = element.posicao_id;
                              a.st = element.status_id;
                              a.pontos_num = element.pontos_num;
                              a.pr = element.preco_num;
                              a.variacao_num = element.variacao_num;
                              a.media_num = element.media_num;
                              a.jogos_num = element.jogos_num;
                              a.scout = element.scout;
                              novosAtletas.push(a);
                         });
                    }
                    this.userTeam.reservas = novosAtletas;
               },
               //User Team
                    get_user_team(stop){
                         if(this.user != null){
                              var vm = this;
                              // console.log('=======================================')
                              // console.log(vm.cors_page+vm.$API_GLOBO+'/auth/time')
                              // console.log("Bearer " + vm.user.token)
                              // console.log('=x=x=x=x=x=x=x=x=x=x=x=x=x=x=x=x=x=x=x=')
                              
                              $.ajax({
                                   type: "GET",
                                   url: vm.cors_page+vm.$API_GLOBO+'/auth/time',
                                   headers: {
                                        'Authorization': "Bearer " + vm.user.token,
                                   },
                                   success: function (result) {
                                        vm.userTeam = result;
                                        vm.parse_atletas_userTeam();
                                        vm.parse_reservas_userTeam();
                                        localStorage.setItem('userTeam',JSON.stringify(result));
                                        vm.adjust_team_order();
                                        vm.getReservas(vm.userTeam.reservas);
                                        vm.reserva_luxo_id = result.reserva_luxo_id

                                   },
                                   error: function (error) {
                                        if(error.responseJSON != null && error.responseJSON.mensagem == 'Time não encontrado'){
                                             vm.warning = 'time';
                                        }else{
                                             if (stop!=false) {
                                                 vm.atualiza_usuario();
                                                 vm.get_user_team(false);
                                             } else {
                                                  vm.warning = 'busca';
                                             }
                                        }
                                   }
                              });
                         }
                    },

                    atualiza_usuario() {
                         
                         if(this.user != null){
                              var vm = this;
                              this.$http
                              .get(this.$PATH+'/user/refresh/?uuid='+encodeURIComponent(vm.aesEncrypt(vm.user.uuid)))
                              .then(response => {
                                   // console.log('* rsp:')
                                   // console.log(response)
                                   var retorno = JSON.parse(vm.aesDencrypt(response.data));
                                   // console.log('* ret:')
                                   // console.log(retorno)
                                   vm.set_usuario_logado(retorno)
                              })
                              .catch(function (error) {
                                   
                                   // eslint-disable-next-line no-undef
                                   // Swal.fire({
                                   //      title: 'Sessão expirada.',
                                   //      text: "Parece que sua Sessão no cartola expirou! Você será deslogado. \n Por gentileza, efeture o login novamente usando seu aplicativo móvel.",
                                   //      type: 'warning',
                                   // }).then(() => {
                                   //      vm.reset_usuario_logado()
                                   //      document.location.reload(true)
                                   // })
                                   // eslint-disable-next-line no-console
                                   console.error('# ERROR_ATT_USR:')
                                   // eslint-disable-next-line no-console
                                   console.error(error);
                              }); 
                         }
                    },
               //Send Team To...
                    send_team_to_backend(){
                         if(this.market_status.status_mercado == 1){
                              if(this.user != null && this.user.membro == 1){
                                   var vm = this;
                                   if(this.userTeam.atletas.length < 12 || this.userTeam.capitao_id == ''){
                                        // eslint-disable-next-line no-undef
                                        Swal.fire(
                                             'Time incompleto.',
                                             'Seu time ainda não está completo. Lembre-se de escolher o seu capitão!',
                                             'warning'
                                        )
                                   }else{

                                        var atletas = []
                                        $.each(vm.userTeam.atletas,function(index,value){
                                             atletas.push(value.id);
                                        });
                                        
                                        var hasError = false;
                                        var reservas = {};
                                        hasError = hasError || vm.verificaStatusReserva(reservas, 1, vm.reserva1);
                                        if (this.esquemaPossuiLateral()) {
                                             hasError = hasError || vm.verificaStatusReserva(reservas, 2, vm.reserva2);
                                        } else {
                                             vm.reserva2 = null;
                                        }
                                        hasError = hasError || vm.verificaStatusReserva(reservas, 3, vm.reserva3);
                                        hasError = hasError || vm.verificaStatusReserva(reservas, 4, vm.reserva4);
                                        hasError = hasError || vm.verificaStatusReserva(reservas, 5, vm.reserva5);
                                        
                                        var data = {
                                             'esquema' : vm.desired_tactic + 1,
                                             'atletas' : atletas,
                                             'reservas' : reservas,
                                             'capitao' : vm.userTeam.capitao_id,
                                             'reserva_luxo_id': vm.reserva_luxo_id
                                        };

                                        if (!hasError) {
                                             const headers = {
                                                  'Accept': 'application/json',
                                                  'Content-Type': 'application/json' 
                                             };
                                             // console.log(JSON.stringify(data))
                                             this.$http
                                             .post(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+vm.user.uuid, JSON.stringify(data), {headers})
                                             .then(function () {
                                                  if(!vm.userTeamChanges){
                                                       // eslint-disable-next-line no-undef
                                                       Swal.fire(
                                                            'Time enviado para avaliação.',
                                                            'Agora é so aguardar e receber os comentários da guruzada!',
                                                            'success'
                                                       );
                                                  }
                                             })
                                             .catch(function (error) {
                                                  // eslint-disable-next-line no-console
                                                  console.log(error);
                                             }); 
                                        }

                                        if(this.userTeamChanges){
                                             // eslint-disable-next-line no-undef
                                             Swal.fire({
                                                  title: 'E o cartola?',
                                                  text: "Seu time foi enviado para avaliação, mas não foi salvo no cartola, deseja salvar?",
                                                  type: 'warning',
                                                  showCancelButton: true,
                                                  confirmButtonColor: '#28a745',
                                                  cancelButtonColor: '#dc3545',
                                                  confirmButtonText: 'Sim',
                                                  cancelButtonText: 'Não'
                                             }).then((result) => {
                                                  if (result.value)
                                                       vm.send_team_to_cartola();
                                             })
                                        }
                                   }
                              }else if(this.user == null){
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                        'Você está deslogado.',
                                        'Você deve logar e ser assinante para enviar o time para avaliação.',
                                        'warning'
                                   )
                              }else{
                                   // eslint-disable-next-line no-undef
                                   Swal.fire({
                                        title: 'Ops!',
                                        text: 'Essa função é exclusiva para quem faz parte do Time Guru. Quer saber todas as vantagens de fazer parte do nosso time?',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#28a745',
                                        cancelButtonColor: '#dc3545',
                                        confirmButtonText: 'SIM, EU QUERO',
                                        cancelButtonText: 'Não'
                                   }).then((result) => {
                                        if (result.value) {
                                             window.location.href = vm.selling_page;
                                        }
                                   })
                              }
                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops',
                                   'Mercado não está aberto!',
                                   'error'
                              );
                         }
                    },

                    verificaStatusReserva(array, index, reserva) {
                         if (reserva == null || reserva == undefined) {
                              //do nothing
                         } else if (this.isTitular(reserva.id)) {
                              this.alerta('Reserva Irregular', 'O jogador "'+reserva.ap+'" está escalado ao mesmo tempo como titular e reserva.', 'error');
                              return true;
                         } else if (this.getMenorPreco(reserva.ps) < reserva.pr) {
                              this.alerta('Reserva Irregular', 'O jogador "'+reserva.ap+'" não pode ser mais caro que o titular mais barato em sua posição.', 'error');
                              return true;
                         } else {
                              array[index] = reserva.id;
                         }
                    },

                    send_team_to_cartola(){
                         if(this.market_status.status_mercado == 1){
                              this.userTeamChanges = false;

                              if(this.userTeam.atletas.length < 12 || this.userTeam.capitao_id == ''){
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                             'Time incompleto.',
                                             'Seu time ainda não está completo. Lembre-se de escolher o seu capitão!',
                                             'warning'
                                        )
                              }else{
                                   var vm = this;
                                   var atletas = []
                                   $.each(vm.userTeam.atletas,function(index,value){
                                        atletas.push(value.id);
                                   });

                                   var hasError = false;
                                   var reservas = {};
                                   hasError = hasError || vm.verificaStatusReserva(reservas, 1, vm.reserva1);
                                   hasError = hasError || vm.verificaStatusReserva(reservas, 2, vm.reserva2);
                                   hasError = hasError || vm.verificaStatusReserva(reservas, 3, vm.reserva3);
                                   hasError = hasError || vm.verificaStatusReserva(reservas, 4, vm.reserva4);
                                   hasError = hasError || vm.verificaStatusReserva(reservas, 5, vm.reserva5);

                                   var data = {
                                        'esquema' : vm.desired_tactic + 1,
                                        'atletas' : atletas,
                                        'reservas': reservas,
                                        'capitao' : vm.userTeam.capitao_id,
                                        'reserva_luxo_id': this.reserva_luxo_id
                                   }

                                   if (!hasError) {
                                        $.ajax({
                                             type: "POST",
                                             url: vm.cors_page+vm.$API_GLOBO+'/auth/time/salvar',
                                             headers: {
                                                  'Authorization': "Bearer " + vm.user.token,
                                             },
                                             data : JSON.stringify(data),
                                             contentType: "application/json; charset=utf-8",
                                             success: function (result) {
                                                  localStorage.setItem('userTeam',JSON.stringify(vm.userTeam));
                                                  // eslint-disable-next-line no-undef
                                                  Swal.fire(
                                                       'Tudo certo!',
                                                       result.mensagem,
                                                       'success'
                                                  );
                                             },
                                             error: function (error) {
                                                  // eslint-disable-next-line no-console
                                                  console.log(error);
                                                  // eslint-disable-next-line no-undef
                                                  Swal.fire(
                                                       'Ops!',
                                                       'Por favor, verifique seu esquema tático e o capitão escolhido.',
                                                       'error'
                                                  );
                                             }
                                        });
                                        //@TODO
                                        // this.$http
                                        // .post(vm.cors_page+'https://api.cartolafc.globo.com/auth/time/salvar')
                                        // .then(response => {
                                        //      vm.last_post_wp = JSON.parse(response.data)
                                        // })
                                        // .catch(function (error) {
                                        //      console.log('erro:')
                                        //      console.log(error);
                                        // }); 
                                   }
                              }
                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops',
                                   'Mercado não está aberto!',
                                   'error'
                              );
                         }
                    },
               //Approval
                    user_voted(athlete_id,action){
                         if(this.user != null){
                              var r = false;
                              if(this.approvals[athlete_id] != undefined)
                                   if(this.approvals[athlete_id][action] != undefined)
                                        r = JSON.stringify(this.approvals[athlete_id][action]).includes(this.user.uuid);
                              return r;
                         }
                    },
                    store_approval(athlete_id, action){
                         if(this.market_status.status_mercado == 1){

                              var fechamento = this.market_status.fechamento.timestamp;
                              fechamento = parseInt(fechamento + '000');
                              var remaining_time_to_close = fechamento - new Date().getTime();

                              if(remaining_time_to_close < 0){
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                        'Ops',
                                        'Mercado não está aberto!',
                                        'error'
                                   );
                              }else if(this.user != null){
                                   var vm = this;

                                   var reversed_action = null;
                                   action == 'like' ? reversed_action = 'deslike' : reversed_action = 'like';

                                   if(vm.approvals_on_page[action].indexOf(athlete_id) == -1){
                                        if(vm.approvals_on_page[reversed_action].indexOf(athlete_id) == -1){
                                             vm.approvals_on_page[action].push(athlete_id);
                                        }else{
                                             vm.approvals_on_page[reversed_action].splice(vm.approvals_on_page[reversed_action].indexOf(athlete_id),1);
                                             vm.approvals_on_page[action].push(athlete_id);
                                        }
                                   }else{
                                        vm.approvals_on_page[action].splice(vm.approvals_on_page[action].indexOf(athlete_id),1);
                                   }

                                   var rating = 1;

                                   if(action == 'deslike')
                                        rating = 0;

                                   var data = 
                                        'uuid='+vm.user.uuid+'&'+
                                        'rodada='+vm.market_status.rodada_atual+'&'+
                                        'atleta='+athlete_id+'&'+
                                        'rating='+rating;

                                   this.$http
                                   .post(this.$PATH+'/thumbs/'+vm.user.uuid+'/'+vm.market_status.rodada_atual+'/'+athlete_id+'/'+rating, data)
                                   .catch(function (error) {
                                        // eslint-disable-next-line no-console
                                        console.log(error);
                                   }); 
                              }else{
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                        'Você está deslogado.',
                                        'Você deve logar para dar like/deslike em algum jogador.',
                                        'warning'
                                   )
                              }
                         }
                         else{
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops',
                                   'Mercado não está aberto!',
                                   'error'
                              );
                         }
                    },
               //HeatMap
               heatmap(athlete_id, round) {
                    this.$refs.heatMap.heatmap(athlete_id,round);
               },
               //Logic
                    //Adiciona ou Remove atletas do vetor athletes_to_compare
                    to_comparison(athlete){
                         var indexOf = this.athletes_to_compare.indexOf(athlete);
                         indexOf != -1
                              ? this.athletes_to_compare.splice(indexOf,1)//Remove
                              : this.athletes_to_compare.push(athlete);//Add
                    },
                    //Esvazia o vetor athletes_to_compare
                    empty_comparison(){
                         this.athletes_to_compare = [];
                    },
                    //Retorna atletas do time do usuário baseado na ps_nome
                    get_athletes_at(ps_nome){
                         var vm = this;
                         var result = [];
                         $.each(this.userTeam.atletas,function(index,value){
                              if(vm.positions[value.ps].abreviacao == ps_nome){
                                   result.push(value);
                              }
                         }); 
                         
                         if(this.remaining_positions[ps_nome] > 0){
                              var i = 0;
                              while(i < this.remaining_positions[ps_nome]){
                                  result.push(null);
                                  i += 1;
                              }
                         }

                         this.recalculate_remaining_positions();

                         return result;
                    },
                    //Lógica para a mudança de esquema tático
                    change_tatic(action){
                         var vm = this;

                         var before_sell_search = this.search;
                         var before_sell_club = this.currentClub;
                         var before_sell_positions = this.currentPos;
                         var before_sell_status = this.currentStatus;
                         

                         var key = this.desired_tactic;
                         var tactic_quantity_by_position = this.tactics[key].posicoes;
                         var team_quantity_by_position = this.json_of_current_team_pos();
                         var update_search_positions = [];
                         var difference = 0;

                         this.messages = [
                              "Para definir seu time na formação " + this.tactics[key].nome + " você deve:"
                         ];

                         $.each(tactic_quantity_by_position,function(index,value){
                              difference = value - team_quantity_by_position[index];

                              vm.remaining_positions[index] = difference;

                              if(difference < 0){
                                   //REMOVE
                                   vm.messages.push("Remover "+ -1 * difference +" jogador(es) da posição " + index.toUpperCase());
                                   update_search_positions.push(index);
                              }
                         });

                         if(this.messages.length == 1)
                              this.messages = [];

                         if(this.messages.length >= 1){
                              $('#restrictions').modal('show');
                              this.showRestricaoCampinho = true;
                              this.order_by('ps');
                         }

                         //Cleaning search after completing restrictions of change of tactic
                         if(this.messages.length == 0){
                              this.userTeam.time.esquema_id = this.desired_tactic + 1;
                              this.search = '';
                         }

                         if(action == 'close'){
                              if(this.messages.length > 0)
                                   this.rebought_athletes();

                              this.to_be_rebought = [];

                              this.desired_tactic = this.userTeam.time.esquema_id - 1;
                              this.messages = [];

                              setTimeout(function(){
                                   vm.reset_filters();
                              },500);
                         }

                         if(action != 'comprar'){
                              this.reset_filters();
                              this.currentStatus=[];
                              this.update_search_by_positions(update_search_positions);
                         }

                         this.recalculate_remaining_positions();

                         if(action == 'vender'){
                              this.search = before_sell_search;
                              this.currentClub = before_sell_club;
                              this.currentPos = before_sell_positions;
                              this.currentStatus = before_sell_status;
                         }

                         if(action == 'done'){
                              this.reset_filters();
                         }
                    },
                    //Atualiza o vetor remainig_positions
                    recalculate_remaining_positions(){
                         var vm = this;

                         var key = this.desired_tactic;
                         var tactic_quantity_by_position = this.tactics[key].posicoes;
                         var team_quantity_by_position = this.json_of_current_team_pos();
                         var difference = 0;

                         $.each(tactic_quantity_by_position,function(index,value){
                              difference = value - team_quantity_by_position[index];

                              vm.remaining_positions[index] = difference;

                         });
                    },
                    //Ajusta os atletas do time do usuário para a ordem desejada GOL LAT ZAG MEI ATA TEC
                    adjust_team_order(){
                         var gol = [];
                         var lat = [];
                         var zag = [];
                         var mei = [];
                         var ata = [];
                         var tec = [];
                         $.each(this.userTeam.atletas,function(index,value){
                              value.ps == 1 ? gol.push(value) : null;
                              value.ps == 2 ? lat.push(value) : null;
                              value.ps == 3 ? zag.push(value) : null;
                              value.ps == 4 ? mei.push(value) : null;
                              value.ps == 5 ? ata.push(value) : null;
                              value.ps == 6 ? tec.push(value) : null;
                         });
                         this.userTeam.atletas = gol.concat(lat,zag,mei,ata,tec);
                    },
                    //Retorna um json que contem a quantidade de atletas em cada uma das posições
                    json_of_current_team_pos(){
                         
                         var gol = 0;
                         var lat = 0;
                         var zag = 0;
                         var mei = 0;
                         var ata = 0;
                         var tec = 0;
                        
                         $.each(this.userTeam.atletas,function(index,value){
                              value.ps == 1 ? gol+=1 : gol+=0;
                              value.ps == 2 ? lat+=1 : lat+=0;
                              value.ps == 3 ? zag+=1 : zag+=0;
                              value.ps == 4 ? mei+=1 : mei+=0;
                              value.ps == 5 ? ata+=1 : ata+=0;
                              value.ps == 6 ? tec+=1 : tec+=0;
                         });

                         var json = {
                              "gol": gol,
                              "lat": lat,
                              "zag": zag,
                              "mei": mei,
                              "ata": ata,
                              "tec": tec
                         };

                         return json;
                    },
                    //Verifica se o usuário pode comprar um certo atleta baseado no patrimônio e no esquema tático
                    check_possibility_to_buy(athlete){
                         //Check current tactic and if it's possible to add that athlete
                         //Important: 0->1, 1->2 ... f(x) = x + 1 when dealing with tactics keys
                         var key = this.userTeam.time.esquema_id - 1;
                         var tactic_quantity_by_position = this.tactics[key].posicoes;
                         var team_quantity_by_position = this.json_of_current_team_pos();
                         var athlete_position = this.positions[athlete.ps].abreviacao;

                         return team_quantity_by_position[athlete_position] + 1 <= tactic_quantity_by_position[athlete_position] ;
                    },
                    //Compra um atleta para o time do usuário
                    buy_athlete(athlete){
                         if(this.market_status.status_mercado == 1){
                              this.userTeamChanges = true;
                              if(this.userTeam.atletas != null && this.userTeam.atletas.length == 12){
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                        'Seu time está completo.',
                                        'Venda algum jogador antes de tentar realizar a próxima compra.',
                                        'warning'
                                   )
                              }else if(this.check_possibility_to_buy(athlete)){
                                   if(this.get_userTeam_total_price() + athlete.pr <= this.userTeam.patrimonio){
                                        this.laterais = 0;
                                        this.userTeam.atletas.push(athlete);
                                        this.adjust_team_order();
                                        athlete['incluso_no_time'] = true;
                                   }else{
                                        // eslint-disable-next-line no-undef
                                        Swal.fire(
                                             'Você não tem patrimônio suficiente.',
                                             '',
                                             'warning'
                                        )
                                   }
                              }else{
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                        "Posição " + this.positions[athlete.ps].nome + " está completa.",
                                        'Venda algum jogador dessa posição.',
                                        'warning'
                                   )
                              }

                              this.change_tatic('comprar');
                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops',
                                   'Mercado não está aberto!',
                                   'error'
                              );
                         }
                    },
                    //Vende um atleta para o time do usuário
                    sell_athlete(athlete){
                         if(this.market_status.status_mercado == 1){
                              if(this.userTeam.capitao_id == athlete.id)
                                   this.userTeam.capitao_id = '';

                              this.userTeamChanges = true;
                              this.laterais = 0;
                              var vm = this;

                              if(this.messages.length > 0)
                                   this.to_be_rebought.push(athlete);
                              
                              $.each(this.userTeam.atletas, function(index,value){
                                   if(value.id == athlete.id){
                                        vm.userTeam.atletas.splice(index,1);
                                        return false;
                                   }
                              });

                              athlete['incluso_no_time'] = false;

                              this.change_tatic('vender');
                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops',
                                   'Mercado não está aberto!',
                                   'error'
                              );
                         }
                    },
                    altera_reserva_luxo(athlete_id){
                         if (this.reserva_luxo_id == athlete_id) {
                              this.reserva_luxo_id = 0
                         } else {
                              this.reserva_luxo_id = athlete_id
                         }
                    },
                    //Remove um atleta do banco de reservas
                    remove_reserva(athlete){
                         if(this.market_status.status_mercado == 1){
                              this.userTeamChanges = true;
                              // this.laterais = 0;
                              // var vm = this;

                              // if(this.messages.length > 0) {
                              //      this.to_be_rebought.push(athlete);
                              // }
                              if (this.reserva1 && this.reserva1.id == athlete.id) {
                                   this.reserva1 = null;
                              } else if (this.reserva2 && this.reserva2.id == athlete.id) {
                                  this.reserva2 = null;
                              } else if (this.reserva3 && this.reserva3.id == athlete.id) {
                                   this.reserva3 = null;
                              } else if (this.reserva4 && this.reserva4.id == athlete.id) {
                                   this.reserva4 = null;
                              } else if (this.reserva5 && this.reserva5.id == athlete.id) {
                                   this.reserva5 = null;
                              }
                              
                              // $.each(this.userTeam.reservas, function(index,value){
                              //      if(value.id == athlete.id){
                              //           vm.userTeam.reservas[index] = {};
                              //           return false;
                              //      }
                              // });

                              // athlete['incluso_no_time'] = false;

                              // this.change_tatic('vender');
                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops',
                                   'Mercado não está aberto!',
                                   'error'
                              );
                         }
                    },
                    //Realiza a compra de atletas que foram vendidos mas por alguma restrição no método change_tactic precisaram ser recomprados
                    rebought_athletes(){
                         var vm = this;
                         $.each(this.to_be_rebought,function(index,value){
                              value.incluso_no_time = true;
                              if(vm.userTeam.atletas.length < 12)
                                   vm.userTeam.atletas.push(value);
                         });
                         this.to_be_rebought = [];
                    },
                    //Atualiza o filtro de currentPos para as posições que forem passadas como argumento
                    update_search_by_positions(positions){
                         var vm = this;
                         this.currentPos = [];
                         var filtered_athletes = [];

                         $.each(this.userTeam.atletas,function(index,value){
                              if(positions.indexOf(vm.positions[value.ps].abreviacao) !== -1){
                                   filtered_athletes.push(value.atleta_id);
                                   vm.currentPos.indexOf(String(value.ps)) == -1 
                                        ? vm.currentPos.push(String(value.ps)) : null;
                              }
                         }); 

                         this.search = filtered_athletes.join(' + ');

                         if(filtered_athletes.length == 1)
                              this.search = this.search + ' + ';
                    },
                    //Verifica se o vetor remaining_positions só possui zeros
                    only_zeros_in_remaining_positions(){
                         var aux = 1;
                         $.each(this.remaining_positions,function(index,value){
                              if(value != 0)
                                   aux = 0;
                         });
                         return aux;
                    },
                    get_userTeam_total_price(){
                         var total = 0;

                         $.each(this.userTeam.atletas,function(index,value){
                              total += value.pr;
                         });

                         return total;
                    },
                    get_userTeam_remaining_money(){
                         var c = this.userTeam.patrimonio - this.get_userTeam_total_price().toFixed(2);
                         if (isNaN(c)) {
                              return "-"
                         }
                         return c.toFixed(2);
                    },
               //Auxiliar
                    get_date_now(){
                         var today = new Date();
                         var dd = today.getDate();
                         var mm = today.getMonth() + 1;
                         var hh = today.getHours();
                         var min = today.getMinutes();
                         var ss = today.getSeconds();


                         var yyyy = today.getFullYear();
                         if (dd < 10) {
                              dd = '0' + dd;
                         } 
                         if (mm < 10) {
                              mm = '0' + mm;
                         }

                         return (yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss);
                    },
                    reverse_table(id){
                         $('#'+id).each(function(){
                             var list = $(this).children('tr');
                             $(this).html(list.get().reverse())
                         });
                    },
                    remove_acentos(s){
                         return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    },
                    find_athlete_in_table(athlete_id){
                         this.search = athlete_id + ' + ';
                         this.currentClub = [],
                         this.currentPos = [],
                         this.currentStatus = [],

                         $('html, body').animate({
                              scrollTop: $("#main_table").offset().top - 100
                         }, 1500);
                    },
                    reset_filters(){
                         this.search = '';
                         this.currentClub = [];
                         this.currentPos = [];
                         this.currentStatus = ['7'];
                    },
                    //When field add is clicked scroll window view to main table
                    go_to_table(position_id, reserva){
                         var id = [];

                         if (reserva) {
                              this.filtro_reservas = true;
                         } else {
                              this.filtro_reservas = false;
                         }
                         
                         $('html, body').animate({
                              scrollTop: $("#main_table").offset().top - 100
                         }, 1500);
                         

                         if(position_id == parseInt(position_id)){
                              id = position_id;
                         }else{
                              $.each(this.positions,function(index,value){
                                   if(value != undefined)
                                        if(value.abreviacao == position_id){
                                             id.push(String(index));
                                        } 
                              }); 
                         }

                         //reseting current search terms [id's of palyers for example can be trash in this moment]
                         this.search = '';
                         //setting current position
                         this.currentPos = id;
                    },
                    //Return only the first name of the athlete
                    first_name(name){
                         return name;
                         //return name.split(' ')[0];
                    },
                    //Method to return athlete photo based on it's size.
                    athlete_photo(src){
                         if(src)
                              // return src.replace('FORMATO',size+'x'+size);
                              return src.replace('FORMATO','220x220');
                              // return src;
                         else
                              return 'https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png';
                    },
                    class_laterais(athlete_id){
                         if(this.laterais > athlete_id){
                              return 'order-1';
                         }
                         else{
                              this.laterais = athlete_id;
                              return 'order-4';
                         }
                    },
                    
                    //Table
                    adjust_table_to_left(){
                         $('#main_table').scrollLeft(0);
                    },
                    //Order by changing sort on click
                    order_by(currentSort) {
                         this.currentOrder *= -1;
                         this.ordenar(this.app_escalar, currentSort, this.currentOrder);
                    },
                    ordenar(colecao, currentSort, order) {
                         this.orderBy = currentSort;
                         this.currentSort = currentSort;
                         var app = this;
                         return colecao.sort(function(a,b){
                              if (app.currentSort=='apv') {
                                   if (app.approvals[a.id] && app.approvals[b.id]) {
                                        return (app.approvals[a.id].apv - app.approvals[b.id].apv)*(order);
                                   } else if (app.approvals[a.id]) {
                                        return 1*order;
                                   } else if (app.approvals[b.id]) {
                                        return -1*order;
                                   } else {
                                        return 0;
                                   }
                              } else {
                                   return (a[app.currentSort] - b[app.currentSort])*(order);
                              }
                         })
                    },

                    //Increase view based on scroll
                    increase_view:function(){
                         this.scrollTopTable = $("#main_table").scrollTop();
                         if((-1)*($("#main_table").scrollTop() + $("#main_table").innerHeight() - $("#main_table")[0].scrollHeight) <= 5){
                              this.pageSize += 10;
                         }
                    },
          },
          computed:{
               computed_escalar(){
                    if (this.currentHome == 0) { 
                         // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                         this.allHomeFilter = -1 
                    } else {
                         // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                         this.allHomeFilter = 0;
                    }
                    //this.currentHome = this.allHomeFilter === -1;

                    this.app_escalar.filter((item)=>{
                         //Status from Jogadores
                         // if(this.app_escalar_jogadores[item['id']]!=undefined) {
                         //      item['st'] = this.app_escalar_jogadores[item['id']].idStatus;
                         // }

                         item['pr'] = parseFloat(item['pr'].toFixed(2));

                         //pont_min
                         item['pm'] = parseFloat(item['pm']).toFixed(2);

                         //media_scouts
                         if(item['ms'+this.currentHome] == 'ND')
                              item['ms'] = (this.currentOrder*parseFloat(100)).toFixed(2);
                         else
                              item['ms'] = parseFloat(item['ms'+this.currentHome]).toFixed(2);

                         //pontcedida_scouts
                         if(item['pc'+this.currentHome] == 'ND')
                              item['pc'] = (this.currentOrder*parseFloat(100)).toFixed(2);
                         else
                              item['pc'] = parseFloat(item['pc'+this.currentHome]).toFixed(2);

                         //Checando se o atleta está incluso no time ou não
                         if(this.userTeam.atletas != undefined){
                              var id = this.userTeam.atletas.findIndex(i => i.id === item.id);
                              if(id != -1){
                                   item['incluso_no_time'] = true;
                                   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                   this.userTeam.atletas[id]['media_scouts'] = item['media_scouts'];
                              }else{
                                   item['incluso_no_time'] = false;
                              }
                         }

                         //Checando se o atleta está marcado para comparação ou não
                         var indexOf = this.athletes_to_compare.indexOf(item);
                         indexOf != -1 
                              ? item['marcado_para_comparacao'] = true 
                              : item['marcado_para_comparacao'] = false;
                    });

                    //Aplicando filtros nos jogadores dentro de app_escalar

                    
                    return this.ordenar(this.app_escalar, this.orderBy, this.currentOrder)
                         .filter((item)=>{
                              if(this.search.toLowerCase().includes(' + ')){
                                   var searchTerms = this.search.toLowerCase().split(' + ');
                                   return searchTerms.indexOf(String(item.id).toLowerCase()) !== -1;
                              }else{
                                   return String(this.remove_acentos(item.ap)).toLowerCase().includes(this.remove_acentos(this.search.toLowerCase()));
                              } 
                         })
                         .filter((item)=>{
                              return (this.currentClub.length > 0) ? this.currentClub.indexOf(String(item.cb)) != -1 : true;
                         })
                         .filter((item)=>{
                              return (this.currentPos.length > 0) ? this.currentPos.indexOf(String(item.ps)) != -1 : true;
                         })
                         .filter((item)=>{
                              return (this.currentStatus.length > 0) ? this.currentStatus.indexOf(String(item.st)) != -1 : true;
                         })
                         .filter((row, index) => {
                              let start = (this.currentPage-1)*this.pageSize;
                              let end = this.currentPage*this.pageSize;
                              if(index >= start && index < end) return true;
                         }); 
               },
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/field.scss';
     @import '@/assets/scss/field_table.scss';
     @import '@/assets/scss/main_table.scss';
     @import '@/assets/scss/general.scss';
//     @import '@/assets/scss/heatmap.scss';
     @import '@/assets/scss/custom_checkbox.scss';

     .info-cap {
          cursor: pointer;
     }
     .info-enabled {
          // background-color: #007bff;
          // padding: 0px 3px;
          color: $verde_claro;
          // border-radius: 5px;
          // color: $verde_claro;
          // vertical-align: bottom;
          
     }
     .info-disabled {
          background-color: white;
          padding: 0px 3px;
          color: #007bff;
          border-radius: 5px;
          // color: $verde_claro;
          vertical-align: bottom;
          
     }

     .div-clube-name {
          margin-top: 0px;
          margin-bottom: 10px;
     }

     .clube-name {
          color: white;
          margin-top: 8px;
     }

     .wpp_gif{
          width: 250px;
     }
     @media only screen and (max-width: 1000px) {
          .wpp_gif{
               width: 200px;
          }    
     }

     .guru-value {
          font-weight: bold;
          font-size: 1.8em;
     }

     .tableVariable{
          background-color: transparent;
          border: none;
          color: white;
          font-size: 11px;
          font-weight: bolder;
          option{
               color: #373e5b;
          }
     }

     .titulo-reservas {
          border-bottom: 2px solid lightgray;
          // color: darkgray;
          color: $verde;
          font-weight: bold;
          font-family: "Montserrat", sans-serif;
          margin-bottom: .8em;
          font-size: 1.3em;
          margin-top: .4em;
     }

     .titulo-reservas2 {
          border-bottom: 2px solid lightgray;
          color: darkgray;
          font-weight: bold;
          font-family: "Montserrat", sans-serif;
          font-size: 13px;
          // margin-bottom: .5em;
     }
     
     .respos {
          color: #4F4F4F;
          // background-color: #F5F5F5;
          font-weight: bold;
          font-family: "Montserrat", sans-serif;
          font-size: 13px;
     }

     // #reservas {
          // border: 1px solid red;
          // margin-top: 50px;
     // }

     // .theader {
     //      border-top: 2px solid orange;
     //      border-bottom: 2px solid orange;
     // }

     .tfooter {
          background-color: $cinza_escuro; 
          color: white;
          padding: 0px 10px;
          font-size:11px;
     }
     .tfooter > tr > td {
          padding-top:5px;
          padding-bottom:5px;
          // border-bottom: 2px solid orange;
          // border-top: 2px solid orange;
     }
     .field-selector {
          height: calc(1.5rem + 2px);
     }

     .warning{
          color: $light_text_color;
          padding-bottom: 15px;
     }

     .nav-tabs .nav-item{
          background-color: transparent !important;
     }

     .wpp_icon{
          font-size: 40px;
          color: $light_text_color;
          margin: 25px 10px;
          transition: all 0.3s;
     }
     .wpp_icon:hover{
          transform: translateY(-5px);
          cursor: pointer;
          color: #28a745;
     }
     .wpp_icon::after{
          display: block;
          content: 'Compartilhe';
          color: $light_text_color;
          letter-spacing: 2px;
          font-size: 11px;
     }

     #activate_modal{
          font-size: $table_header_font_size;
          position: fixed;
          background-color: #009865;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          color: #fff;
          padding: 10px;
          top: 10%;
          // right: -20px;
          margin: 20px;
          z-index: 100;
          width: fit-content;
     }

     .messages{
          td{
               color: white;
               font-weight: bolder;
               ul{
                    li{
                         list-style-type: none;
                    }
               }
          }
     }

     .escudo-clube {
          border-bottom: 3px solid green;
     }
     .escudo-adversario {
          border-bottom: 3px solid orange;
     }

     h3 {
          color: orange;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
     }

     #comparison_table{
          width:100%; overflow:auto;
          table{
               thead tr td{
                    text-align:center;
               }

               td:nth-child(odd) {
                    background: #fbfbfb;
               }

               //Making the header Sticky.
               th{
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
               }
               //Making the second column of the table Sticky.
               th:nth-child(1), td:nth-child(1){
                    background-color: white;
                    position: -webkit-sticky;
                    position: sticky;
                    left: 0px;
               }
               td,th{
                    width: 50px;
               }
               td{
                    text-align:center;
               }
               th{
                    text-align: right;
               }
          }
          #graph{
               height: 75px;
               background-color: white;
               border: 1px solid #373e5b1a;
               #fill{
                    background-color: $cinza_muito_claro;
               }
               p{
                    margin-top: -50px;
                    font-weight: bolder;
                    color: $laranja;
               }
          }
     }

     @media only screen and (max-width: 1000px) {
          #comparison_table{
               font-size: 12px;
               #graph{
                    height:50px;
                    p{
                         margin-top:-35px;
                    }
               }
          }
     }

     .page-escalacao {
          padding-top: 20px;
     }

     /* reservas */
     .res {
          padding: 0px;
          img{
               width: 50px;
               background-color: white;
               padding: 2px;
               border-radius: 50%;
               margin-bottom: 5px;
               -webkit-transition: 0.2s all ease;
               transition: 0.2s all ease;
          }
          img:hover{
               width: 55px;
          }
          .athlete_img_hover{
               width: 55px;
          }
          i{
               color: white;
               position: absolute;
               left: 50%;
               margin-left: -30px;
               top: -10px;
               padding: 5px 6px;
               border-radius: 45%;
          }
          i:first-child{//Leader
               margin-left: -35px;
               background-color: $laranja;
               transition: .2s;
          }
          i:hover{
               cursor: pointer;
               background-color: orange;
          }
          i:nth-child(2){//Sell
               margin-left: 15px;
               background-color: #5b5b5b;
               transition: .2s;
          }
          i:nth-child(2):hover{
               background-color: black;
          }

          .club2{
               max-width: 20px;
               padding: 0;
               background-color: transparent;
               margin: 0;
               border: none;
               position: relative;
               margin-left: -20px;
               margin-top: 20px;
          }
          .club2:hover{
               width: 20px;
          }
          .span-reserva{
               white-space: nowrap;
               font-weight: bolder;
               background-color: white;
               color: #373e5b;
               padding: 2px 10px;
               border-radius: 10px;
               font-size: 12px;
          }
          .clickable {
               cursor: pointer;
          }
          // .athlete_img_hover div.luxo {
          //      // visibility: visible;
          //      border: 2px solid red;
          // }
          div.luxo {
               // background-color: #CCC;
               // visibility: hidden;
               cursor: pointer;
               position:absolute;
               width:28px;
               margin-left:10px;
               right:25%;
               top:-10px;
               // border: 2px solid red;
               // background-color: orange;
          }
          div.luxo img:hover {
               padding: 4px;
          }
          // img.luxo-hover:hover {
          //      border:3px solid red;
          // }
     }

     .add-btn {
          font-size: 3em;
          color: gray;
          cursor: pointer;
     }

     .btn-remover {
          color: #f47607;
          border: 1px solid #f47607;
     }
     .btn-remover:hover {
          background-color: #f47607;
          color: white;
     }

     .jogador-warning {
          border: 3px solid red;
     }

     .jogador-error {
          background: radial-gradient(circle, #ec5353 0%, red 100%);
          border: 1px solid red;
     }
     .jogador-error::before {
          content: "MUITO CARO!";
     }

     .btn-reserva {
          color: gray;
          border: 1px solid gray;
     }
     .btn-reserva:hover {
          background-color: gray;
          color: white;
     }
     .pname {
          font-size: 1em;
          // background-color: rgba(255, 255, 255, 0.5);
          // margin: 2px;
          // border-radius: 10px;
     }

     /* range - ultimas rodadas */
     input[type=range]::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          height: 1.1em;
          width: 1.1em;
          border-radius: 50%;
          background: white;
          margin-top: -4px;
     }
</style>
